import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { AddTicketFormValues, FILE_FORMAT } from "src/models/Ticket.model";
import TicketForm from "../Layouts/TicketForm";
import { useNavigate, useParams } from "react-router-dom";
import { useAddTicketMutation } from "src/services/TicketService";
import { showToast } from "src/utils/showToaster";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";

const AddTicketFormWrapper = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [addTicket] = useAddTicketMutation();

  // Form Initial Values
  const initialValues: AddTicketFormValues = {
    projectId: projectId,
    title: "",
    priority: "",
    description: "",
    attachments: [],
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: string().required("Please enter project"),
    title: string().required("Please enter title"),
    priority: object().required("Please enter priority"),
  });

  // Handle Submit
  const handleSubmit = (
    values: AddTicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddTicketFormValues>
  ) => {
    const formatedValues = {
      ...values,
      priority: values?.priority?.value,
      sourceName: "CLIENT",
      attachments: values?.attachments?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
    };

    addTicket(formatedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
        setSubmitting(false);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          setSubmitting(false);
          navigate(`/project/${projectId}`);
        } else {
          showToast("error", res?.data?.message);
          setSubmitting(false);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <SideNavLayout>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form className="h-full">
            <div className="flex justify-center ">
              <div className="w-[90%]">
                <TicketForm
                  formikProps={formikProps}
                  onClose={() => navigate(`/project/${projectId}`)}
                  formType="Add"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </SideNavLayout>
  );
};

export default AddTicketFormWrapper;
