import { Chip } from "@mui/material";
import { useMemo } from "react";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import { SingleTicketResponseType } from "src/models/Ticket.model";
import { HTMLFormatRegex } from "src/utils/validations/formatRegex";
import "video-react/dist/video-react.css";

type Props = {
  ticketDetails: SingleTicketResponseType;
};

export const getDataWithRightFormat = (data: string) => {
  if (data) {
    if (HTMLFormatRegex.test(data)) {
      return <div dangerouslySetInnerHTML={{ __html: data }} />;
    } else {
      return <ATMMarkdownContainer markdown={data} />;
    }
  } else {
    return "-";
  }
};

const TicketDetails = ({ ticketDetails }: Props) => {
  const getDescription = useMemo(
    () => getDataWithRightFormat(ticketDetails?.description),
    [ticketDetails?.description]
  );
  return (
    <div className="h-full overflow-auto p-4 flex flex-col gap-4">
      <div className="flex justify-between">
        {ticketDetails?.projectLabel ? (
          <div>
            <Chip label={ticketDetails?.projectLabel || "-"} />
          </div>
        ) : null}

        <div>
          {ticketDetails?.ticketNumber ? (
            <div className="">
              <Chip
                label={"Ticket Number - " + ticketDetails?.ticketNumber || "-"}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div className="text-gray-500 pb-2 text-lg"> Description </div>

        <div className="mb-5">
        {getDescription}
        </div>
        {ticketDetails?.attachments?.length ? (
          <div className="text-gray-500 pb-2 text-lg"> Assets </div>
        ) : null}

        <div className="grid grid-cols-12 gap-4">
          {ticketDetails?.attachments?.map((attachment: any, index: any) => {
            return (
              <div
                className="col-span-4 md:col-span-4 sm:col-span-6 xs:col-span-12 h-[200px]"
                key={index}
              >
                <ATMRenderFile
                  fileInfo={{
                    ...attachment,
                    renderType: "VIEW",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
