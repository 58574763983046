import React from "react";
import { FiMenu } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { NavItemType } from "../../../navigation";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

type Props = {
  toggleCollapse: () => void;
  isCollapsed: boolean;
  navigation: NavItemType[];
  isPathEqualtoNavItem?: (navItem: any) => boolean;
};

const VerticalNavBar = ({
  toggleCollapse,
  isCollapsed,
  navigation,
  isPathEqualtoNavItem = () => false,
}: Props) => {
  const navigate = useNavigate();

  const { resourceRequestCount } = useSelector((state: RootState) => state.resourceRequest);
  const { projectChatCount } = useSelector((state: RootState) => state.project);
  const { changeRequestCount } = useSelector((state: RootState) => state.changeRequest);

  // Calculate totalUnreadMessages
  const totalUnreadMessages = projectChatCount?.reduce(
    (accumulator: any, project: any) => accumulator + project.totalUnreadMessages,
    0
  );

  function showCounter(navTitle: string) {
    // append here navigation title and it's redux counter state when have to show the bedges count in sidenav layout
    let isVisibleCounterBedgeArray = [
      {
        title: "Project",
        counter: totalUnreadMessages,
      },
      {
        title: "Change-Request",
        counter: changeRequestCount,
      },
      {
        title: "Resource Request",
        counter: resourceRequestCount,
      },
    ];

    for (let bedges of isVisibleCounterBedgeArray) {
      if (bedges?.title === navTitle) {
        return bedges.counter ? (
          <div className="bg-[#2f3131] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white">
            {bedges.counter}
          </div>
        ) : null;
      }
    }

    return false;
  }

  return (
    <div
      className={`h-full  overflow-auto bg-primary-dark transition-all duration-500 border-r border-slate-300 md:static absolute z-[1]  ${isCollapsed
        ? "md:min-w-[0px] md:w-[0px] min-w-0 w-0"
        : "min-w-[230px] w-[230px]"
        }`}
    >
      {/* Logo & Menu Icon */}
      <div
        className={`flex px-4 items-center justify-between sticky top-0 py-3 h-[55px]`}
      >
        {/* Logo */}
        <div
          className={`font-semibold text-xl text-white ${isCollapsed && "md:hidden"
            }`}
        >
          <div className="flex gap-3  items-center "> <img className='w-6 h-6' src='/favicon.ico' alt="icon" /> Codiotic</div>
        </div>

        {/* Menu Icon */}
        <Tooltip title="Alt + T">
          <button
            type="button"
            onClick={toggleCollapse}
            className="text-xl text-white"
          >
            <FiMenu className="hidden md:block" />
            <MdCancel className="block md:hidden text-xl" />
          </button>
        </Tooltip>
      </div>

      {/* Navigations */}
      <div className="  flex flex-col ">
        {navigation?.map((navItem, navIndex) => {
          return (
            <div
              key={navIndex}
              onClick={() =>
                navItem.path &&
                !isPathEqualtoNavItem(navItem) &&
                navigate(navItem.path)
              }
              className={`
                flex
                gap-4
                items-center 
                py-3 
                px-4
                cursor-pointer 
                ${!isPathEqualtoNavItem(navItem) && "hover:bg-primary-light "} 
                transition-all
                duration-300
                text-white
                2xl:text-base md:text-body-1
                font-normal
                ${isPathEqualtoNavItem(navItem)
                  ? "bg-primary-main font-semibold"
                  : ""
                } 
                `}
            >
              <div className="">
                <navItem.icon />
              </div>
              <div
                className={`flex justify-between mt-1 grow ${isCollapsed && " md:hidden"
                  }`}
              >
                {navItem.label}
                {showCounter(navItem.label)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalNavBar;
