/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
    setActivityDateFilter,
    setItems,
} from "src/redux/slices/ActivitySlice";
import { AppDispatch, RootState } from "src/redux/store";
import { object, string } from "yup";
import ActivityDateFilterDialogForm from "./ActivityDateFilterDialogForm";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  startDate: string | null;
  endDate: string | null;
};

const ActivityDateFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { activityDateFilter } = useSelector(
    (state: RootState) => state.activity
  );
  const initialValues: FormInitialValues = {
    startDate: activityDateFilter?.startDate,
    endDate: activityDateFilter?.endDate,
  };

  const validationSchema: any = object({
    startDate: string().nullable(),
    endDate: string().nullable(),
  });

  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    await dispatch(setItems([]));
    dispatch(
      setActivityDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
      })
    );
    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(setItems([]));
    dispatch(
      setActivityDateFilter({
        startDate: "",
        endDate: "",
      })
    );
    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <ActivityDateFilterDialogForm
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ActivityDateFilterFormDialogWrapper;
