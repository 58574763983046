import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChangeRequestListingWrapper from "./screens/ChangeRequest/List/ChangeRequestListingWrapper";
import TicketsViewWrapper from "./screens/ChangeRequest/TicketsView/TicketsViewWrapper";
import LandingPage from "./screens/LandingPage";
import LoginFormWrapper from "./screens/Login/LoginFormWrapper";
import MeetingListingWrapper from "./screens/Meeting/List/MeetingListingWrapper";
import MeetingViewTabsLayout from "./screens/Meeting/View/MeetingViewTabsLayout";
import ProjectSingleView from "./screens/Project/View/ProjectSingleView";
import ProjectViewWrapper from "./screens/Project/View/ProjectViewWrapper";
import AddResourceRequestFormWrapper from "./screens/ResourceRequest/Add/AddResourceRequestFormWrapper";
import ResourceRequestListingWrapper from "./screens/ResourceRequest/List/ResourceRequestListingWrapper";
import ViewResourceRequestFormWrapper from "./screens/ResourceRequest/View/ViewResourceRequestFormWrapper";
import AddTicketFormWrapper from "./screens/Tickets/Add/AddTicketFormWrapper";
import EditTicketFormWrapper from "./screens/Tickets/Edit/EditTicketFormWrapper";
import ActivityListingWrapper from "./screens/Activity/List/ActivityListingWrapper";
import TicketChatWrapper from "./screens/Tickets/View/TicketTabs/Chat/TicketChatWrapper";
import TicketSummary from "./screens/Tickets/View/TicketTabs/Summary/TicketSummary";
import TicketDetailsWrapper from "./screens/Tickets/View/TicketTabs/TicketDetails/TicketDetailsWrapper";
import TicketViewLayout from "./screens/Tickets/View/TicketViewLayout";
import CloseTickets from "./screens/Tickets/View/TicketTabs/CloseTicket/CloseTicketRemark";
import MeetingMOMWrapper from "./screens/Meeting/View/tabs/MeetingMOM/MeetingMOMWrapper";

const PageRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginFormWrapper />} />
          <Route path="/dashboard" element={<LandingPage />} />
          <Route path="/project" element={<ProjectViewWrapper />}>
            <Route path=":projectId" element={<ProjectSingleView />} />
          </Route>
          <Route
            path="/project/:projectId/ticket/add"
            element={<AddTicketFormWrapper />}
          />
          <Route
            path="/project/:projectId/ticket/:ticketId/edit"
            element={<EditTicketFormWrapper />}
          />
          <Route
            path="/project/:projectId/ticket/:ticketId"
            element={<TicketViewLayout />}
          >
            <Route path="details" element={<TicketDetailsWrapper />} />
            <Route path="summary" element={<TicketSummary />} />
            <Route path="chat" element={<TicketChatWrapper />} />
            <Route path="close-remark" element={<CloseTickets />} />
          </Route>
          <Route
            path="/change-request"
            element={<ChangeRequestListingWrapper />}
          />
          <Route
            path="/change-request/:changeRequestId"
            element={<TicketsViewWrapper />}
          />
          <Route path="/activity" element={<ActivityListingWrapper />} />
          <Route
            path="/resource-request"
            element={<ResourceRequestListingWrapper />}
          />
          <Route
            path="/resource-request/:resourceRequestId/add"
            element={<AddResourceRequestFormWrapper />}
          />
          <Route
            path="/resource-request/:resourceRequestId/view"
            element={<ViewResourceRequestFormWrapper />}
          />
          <Route path="/meeting" element={<MeetingListingWrapper />} />
          <Route path="/meeting/:meetingId" element={<MeetingViewTabsLayout />}>
            <Route path="mom" element={<MeetingMOMWrapper />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default PageRoutes;
