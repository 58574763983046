/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { useGetUserProjectQuery } from "src/services/ProjectService";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

type Props = {};

const ProjectViewWrapper = (props: Props) => {
  const navigate = useNavigate();
  const [userProjectData, setUserProjectData] = useState<any>([]);

  const { projectsUnreadMessages } = useSelector((state: RootState) => state.chat);
  const { projectId } = useParams();

  const {
    data: ProjectData,
    isLoading: ProjectDataIsLoading,
    isFetching: ProjectDataIsFetching,
  } = useGetUserProjectQuery("");
  
  useEffect(() => {
    if (!ProjectDataIsFetching && !ProjectDataIsLoading) {
      if (projectId === undefined) {
        navigate(ProjectData?.data?.projectId?.[0]?._id);
      }
      setUserProjectData(ProjectData?.data?.projectId);
    }
  }, [ProjectDataIsFetching, ProjectDataIsLoading, ProjectData, projectId]);

  // useEffect(() => {
  //   if (isLoading || isFetching) {
  //     dispatch(setIsTableLoading(true));
  //   } else {
  //     dispatch(setItems(data?.data || []));
  //     projectId === undefined && navigate(userProjectData?.[0]?._id);
  //     console.log(userProjectData?.[0]?._id ,"userProjectData?.[0]?._id")
  //     dispatch(setTotalItems(data?.totalItem || 0));
  //     dispatch(setIsTableLoading(false));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, isLoading, isFetching]);
  return (
    <SideNavLayout>
      <div className="h-full flex flex-col p-4 gap-4">
        <div className="flex items-center gap-4 p-4 bg-gray-100 rounded-md">
          {/* <span className="text-xl font-semibold text-slate-600">Project:</span> */}
          {userProjectData?.map((project: any) => {
            const isSelected = project?._id === projectId;
            const currentProject = projectsUnreadMessages?.find(
              (ele) => ele?.projectId === project?._id
            );
            return (
              <button
                key={project?._id}
                type="button"
                onClick={() => navigate(project?._id)}
                className={` rounded shadow  font-semibold px-4 py-1 transition-all duration-500 capitalize relative ${isSelected
                    ? "bg-primary-main text-white"
                    : "bg-white text-slate-700"
                  }`}
              >
                {currentProject?.totalUnreadMessages ? (
                  <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center absolute items-center -right-[10px] -top-[6px] text-white">
                    {currentProject?.totalUnreadMessages}
                  </div>
                ) : null}
                {project?.projectName}
              </button>
            );
          })}
        </div>
        <Outlet />
      </div>
    </SideNavLayout>
  );
};

export default ProjectViewWrapper;
