import React from "react";
import { FieldArray, FormikProps } from "formik";
import { RiDeleteBin5Line } from "react-icons/ri";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import MOLProjecttDropdown from "src/components/UI/molecules/MOLProjectDropdown/MOLProjectDropdown";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import MOLFeatureDropdown from "src/components/UI/molecules/MOLFeatureDropdown/MOLFeatureDropdown";
import { TicketFormValues } from "src/models/Ticket.model";

type Props = {
  formikProps: FormikProps<TicketFormValues>;
  onClose: () => void;
};

const EditTicketAffectedAreas = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Update Affected Areas </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <FieldArray name="affectedAreas">
        {({ push, remove }) => (
          <div className="flex flex-col gap-6">
            {values?.affectedAreas?.map((affectedArea, index) => (
              <div key={index} className="flex gap-4 items-end">
                {/* Project */}
                <div className="flex-1">
                  <MOLProjecttDropdown
                    name={`affectedAreas[${index}].projectId`}
                    label="Project"
                    placeholder="Select project"
                    value={values?.affectedAreas[index]?.projectId}
                    onBlur={handleBlur}
                    onChange={(newValue) => {
                      setFieldValue(
                        `affectedAreas[${index}].projectId`,
                        newValue
                      );
                    }}
                  />
                </div>

                {/* Feature */}
                <div className="flex-1">
                  <MOLFeatureDropdown
                    name={`affectedAreas[${index}].featureId`}
                    label="Feature"
                    placeholder="Select Feature"
                    value={values?.affectedAreas[index]?.featureId}
                    onBlur={handleBlur}
                    onChange={(newValue) => {
                      setFieldValue(
                        `affectedAreas[${index}].featureId`,
                        newValue
                      );
                    }}
                  />
                </div>

                {/* Delete Button */}
                <button
                  type="button"
                  className={`${getInputHeight(
                    "small"
                  )} aspect-square rounded bg-red-400 text-white flex justify-center items-center `}
                  onClick={() => remove(index)}
                >
                  <RiDeleteBin5Line size={20} />
                </button>
              </div>
            ))}

            {/* Add More Button */}
            <button
              type="button"
              onClick={() =>
                push({
                  projectId: "",
                  featureId: "",
                })
              }
              className="rounded border-dashed border border-slate-500 py-2 font-medium text-md text-gray-400 "
            >
              Add {values?.affectedAreas?.length ? "More" : "Affeted Area"}
            </button>
          </div>
        )}
      </FieldArray>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default EditTicketAffectedAreas;
