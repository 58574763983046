import apiSlice from "./ApiSlice";

export const ResourceRequestServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET RESOURCE REQUEST
    getAllResourceRequest: builder.query({
      providesTags: ["resource-request"],
      query: (body) => {
        return {
          url: "/resource-request/app",
          method: "POST",
          body,
        };
      },
    }),

    // GET RESOURCE REQUEST BY ID
    getResourceRequestById: builder.query({
      providesTags: ["resource-request"],
      query: (id) => {
        return {
          url: `/resource-request/${id}/app`,
          method: "GET",
        };
      },
    }),
    // ADD RESOURCE REQUEST
    addResourceRequest: builder.mutation({
      invalidatesTags: ["resource-request"],
      query: ({ body, id }) => {
        return {
          url: `/resource-request/add-resource/${id}/app`,
          method: "PUT",
          body,
        };
      },
    }),
    // get doubt count
    getResourceRequestCount: builder.query({
      providesTags: ["resource-request"],
      query: () => {
        return {
          url: "/resource-request/count/app",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllResourceRequestQuery,
  useGetResourceRequestByIdQuery,
  useAddResourceRequestMutation,
  useGetResourceRequestCountQuery
} = ResourceRequestServiceApi;
