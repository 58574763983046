import { IconType } from "react-icons";
import { RiInfraredThermometerFill } from "react-icons/ri";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";

type Props = {};

export const meetingViewTabs: {
  label: string;
  icon: IconType;
  path: string;
  moduleName?: string;
  actionName?: string;
}[] = [
  {
    label: "MOM",
    icon: RiInfraredThermometerFill,
    path: "mom",
  },
];

const MeetingViewTabsLayout = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname.split("/")[3];

  return (
    <>
      <SideNavLayout>
        <div className="flex flex-col gap-2 pt-3 h-full">
          {/* Change Request Tabs and Outlet */}
          {meetingViewTabs?.length && (
            <div className="flex gap-3 items-center  mx-4  border-b border-slate-400 bg-white">
              {meetingViewTabs?.map((tab: any, index: any) => {
                return (
                  <div key={index}>
                    <button
                      type="button"
                      onClick={() => navigate(tab.path)}
                      className={`h-full px-5 pb-2 flex gap-2 border-b-[3px]  items-center hover:text-primary-main font-medium text-sm transition-all
        ${
          currentPath === tab.path?.split("/")[0]
            ? "text-primary-main   border-primary-main"
            : "text-gray-700 border-white"
        }
         `}
                    >
                      <div className=" text-lg  ">
                        <tab.icon />
                      </div>
                      {tab.label}
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          <div className="grow overflow-auto ">
            <Outlet />
          </div>
        </div>
      </SideNavLayout>
    </>
  );
};

export default MeetingViewTabsLayout;
