import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { navigation } from "../../../navigation";
import { setIsCollapsed } from "../../../redux/slices/SideNavLayoutSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import Header from "../../UI/Header/Header";
import VerticalNavBar from "../../UI/VerticalNavBar/VerticalNavBar";
import { setResourceRequestCount } from "src/redux/slices/ResourceRequestSlice";
import { setProjectCount } from "src/redux/slices/ProjectSlice";
import { setChangeRequestCount } from "src/redux/slices/ChangeRequestSlice";

import { useGetResourceRequestCountQuery } from "src/services/ResourceRequestService";
import { useGetChangeRequestCountQuery } from "src/services/ChangeRequestService";
import { useGetTotalUnreadMessageProjectQuery } from "src/services/ChatServices";

type Props = {
  children: ReactNode;
  entityName?: ReactNode;
};

const SideNavLayout = ({ children, entityName }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const sideNavLayoutState: any = useSelector(
    (state: RootState) => state.sideNavLayout
  );

  const { isCollapsed } = sideNavLayoutState;

  const toggleCollapse = () => {
    dispatch(setIsCollapsed(!isCollapsed));
  };
  const location = useLocation();
  const currentPath = `/${location.pathname?.split("/")[1]}`;

  // showing // PROJECT COUNT in side nav layout

  const { isLoading: isProjectCountLoading, isFetching: isProjectCountFetching, data: projectCount } = useGetTotalUnreadMessageProjectQuery("");

  React.useEffect(() => {
    if (!isProjectCountLoading && !isProjectCountFetching) {
      dispatch(setProjectCount(projectCount?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectCountLoading, isProjectCountFetching, projectCount]);
  // showing   // CHANGE REQUEST COUNT in side nav layout
  
  const { isLoading: isChangeRequestLoading, isFetching: isChangeRequestFetching, data: changeRequestCount } = useGetChangeRequestCountQuery("");
  React.useEffect(() => {
    if (!isChangeRequestLoading && !isChangeRequestFetching) {
      dispatch(setChangeRequestCount(changeRequestCount?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeRequestLoading, isChangeRequestFetching, changeRequestCount]);

  // showing   // RESOURCE REQUEST COUNT in side nav layout

  const { isLoading: isResourceRequestLoading, isFetching: isResourceRequestFetching, data: resourceRequestCount } = useGetResourceRequestCountQuery("");
  React.useEffect(() => {
    if (!isResourceRequestLoading && !isResourceRequestFetching) {
      dispatch(setResourceRequestCount(resourceRequestCount?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResourceRequestLoading, isResourceRequestFetching, resourceRequestCount]);

  return (
    <div
      className="flex h-screen w-screen relative"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.altKey && e.key === "t") {
          toggleCollapse();
        }
      }}
    >
      {/* Side Navigation Bar */}
      <VerticalNavBar
        toggleCollapse={toggleCollapse}
        isCollapsed={isCollapsed}
        navigation={navigation}
        isPathEqualtoNavItem={(navItem: any) => navItem.path === currentPath}
      />

      <div
        className={`h-full flex flex-col transition-all duration-500 ${isCollapsed ? "w-full" : "w-[calc(100%-230px)]"
          } `}
      >
        {/* Header */}
        <div className="h-[50px]">
          <Header
            toggleCollapse={toggleCollapse}
            isCollapsed={isCollapsed}
            entityName={entityName}
          />
        </div>

        <div className="flex-1 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default SideNavLayout;
