import React from "react";
import { FormikProps } from "formik";
import { LoginFormInitialValues } from "./LoginFormWrapper";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPassword from "src/components/UI/atoms/formFields/ATMPassword/ATMPassword";

type Props = {
  formikProps: FormikProps<LoginFormInitialValues>;
};

const LoginForm = ({ formikProps }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="flex h-screen bg-white justify-center items-center">
      <div className="p-4 flex flex-col gap-6 w-[500px] h-fit border border-slate-300 rounded">
        <div className="flex justify-between items-center">
          <div className="text-xl font-medium"> Login to panel </div>
        </div>

        <div className="flex flex-col gap-4">
          {/* User Name */}
          <div className="">
            <ATMTextField
              name="email"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              label="Email"
              placeholder="Enter Email"
              onBlur={handleBlur}
            />
          </div>

          {/* Password */}
          <div className="">
            <ATMPassword
              name="password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              label="Password"
              placeholder="Enter Password"
              onBlur={handleBlur}
            />
          </div>
        </div>
        <div>
          <ATMLoadingButton isLoading={isSubmitting} type="submit">
            Login
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
