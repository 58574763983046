import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { twMerge } from "tailwind-merge";

type MOLReactQuillEditorTypes = {
  readOnly?: boolean;
  label: string;
  className?: string;
  placeholder?: string;
  value: any;
  height?: any;
  onChange: any;
  onBlur?: () => void;
};

const MOLReactQuillEditor = ({
  label,
  value,
  className,
  placeholder,
  onChange,
  readOnly = false,
  height,
  onBlur,
}: MOLReactQuillEditorTypes) => {
  const [editorContent, setEditorContent] = useState<any>(value);

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    if (onChange) {
      onChange(content);
    }
  };

  useEffect(() => {
    setEditorContent(value);
  }, [value]);


  return (
    <div className={`${twMerge("bg-transparent", `${className}`)}`}>
      <label
        className="text-slate-700 font-medium
       text-sm"
      >
        {label}
      </label>
      <ReactQuill 
        // ref={quillRef}
        readOnly={readOnly}
        value={editorContent}
        placeholder={placeholder}
        defaultValue={"default value"}
        className="rounded"
        onChange={handleEditorChange}
        onBlur={onBlur}
        style={{
          height:height,
        }}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            ["clean"],
            // ["preview"],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "link",
          "image",
        ]}
      />
    </div>
  );
};

export default MOLReactQuillEditor;

