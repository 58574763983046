import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import MOLTicketStatusChip from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import { setDatePage } from "src/redux/slices/ActivityDateSlice";
import { RootState } from "src/redux/store";

type Props = {
  datesData: any;
  paginationProps: {
    onFilterClick: () => void;
  };
};

const ActivityListing = ({
  datesData,
  paginationProps: { onFilterClick },
}: Props) => {
  const dispatch = useDispatch();

  const { datePage, dateTotalItems } = useSelector(
    (state: RootState) => state.activityDate
  );
  // this function will run on scroll Date
  const handleScroll = () => {
    dispatch(setDatePage(datePage + 1));
  };

  return (
      <div className="h-screen w-full flex justify-center">
        <div className="w-[70%] h-full flex flex-col gap-4 pt-5">
          <ATMPageHeader
            pageTitle="Activity"
            hideAddButton
            hideSearchBox
            // onFilterClick={() => onFilterClick()}
          />
          {datesData?.length ? (
            <div
              id="scrollable-activity-div"
              className="w-[calc(100%-2px)] border h-full  overflow-auto p-3 "
            >
              {/* Scroll For Date */}
              <InfiniteScroll
                dataLength={datesData?.length || 0}
                scrollableTarget={"scrollable-activity-div"}
                next={handleScroll}
                scrollThreshold={0.8}
                hasMore={datesData?.length !== dateTotalItems}
                loader={
                  <div className="grid grid-cols-1 gap-3 overflow-auto w-full mt-3">
                    {Array(4)
                      .fill(null)
                      .map((_, index) => (
                        <div
                          key={index}
                          className="animate-pulse  h-[60px] p-2"
                        >
                          <div className="bg-slate-200 h-full rounded" />
                        </div>
                      ))}
                  </div>
                }
              >
                {datesData?.map((item: any, ind: number) => {
                  return (
                    <React.Fragment key={ind}>
                      <div className="w-full bg-slate-200 rounded py-2 px-3 border-b">
                        <span className="font-medium text-primary-light ">
                          {moment(item?.date).format("DD MMM yyyy")}
                        </span>
                      </div>
                      {item?.ticketData?.map((info: any, index: any) => {
                        return (
                          <div
                            key={index}
                            className="border-b flex justify-between items-center gap-3 py-2 px-2 "
                          >
                            <div className="flex flex-col justify-between">
                              <span className="text-slate-700 text-xs">
                                #{info?.ticketNumber}
                              </span>
                              <div className="font-medium  text-[16px]">
                                {info?.title}
                              </div>
                              <div className=" text-slate-600 text-xs pt-1">
                                Updated at{" "}
                                {moment(info?.updatedAt).format("hh:mm A")}
                              </div>
                            </div>
                            <MOLTicketStatusChip
                              status={info?.clientSideTicketStatus}
                            />
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </InfiniteScroll>
            </div>
          ) : (
            <div className="h-[300px] flex justify-center items-center text-[20px] text-red-800 font-medium">
              No Data Found{" "}
            </div>
          )}
        </div>
      </div>
  );
};

export default ActivityListing;
