import React, { ChangeEvent } from "react";
import ATMInputAdormant from "../formFields/ATMInputAdormant/ATMInputAdormant";
import { BiSearch, BiFilter } from "react-icons/bi";
import ATMLoadingButton from "../ATMLoadingButton/ATMLoadingButton";

type Props = {
  pageTitle: string;
  searchValue?: string;
  onSearchChange?: (
    newValue: string,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  buttonProps?: {
    btnName?: string;
    onClick: () => void;
    className?: string;
  };
  hideAddButton?: boolean;
  hideSearchBox?: boolean;
  isFilter?: boolean;
  onFilterClick?: () => void;
};

const ATMPageHeader = ({
  pageTitle,
  searchValue = "",
  onSearchChange,
  hideAddButton = false,
  buttonProps = { btnName: "Add New", onClick: () => {}, className: "" },
  hideSearchBox = false,
  isFilter = false,
  onFilterClick = () => {},
}: Props) => {
  return (
    <div className="flex gap-2 justify-between">
      <div className="text-xl font-semibold text-slate-600">{pageTitle}</div>
      <div className="flex gap-2">
        {isFilter && (
          <button
            onClick={() => onFilterClick()}
            className="bg-white shadow px-2 flex items-center rounded border"
          >
            <BiFilter className="text-2xl text-slate-600" />
          </button>
        )}

        {hideSearchBox ? null : (
          <div className="w-[300px]">
            <ATMInputAdormant
              name=""
              value={searchValue}
              onChange={(e) => onSearchChange?.(e.target.value, e)}
              adormant={<BiSearch />}
              adormantProps={{
                position: "start",
                extraClasses: "bg-white border-0",
              }}
              inputProps={{ className: "bg-white" }}
              placeholder="Search..."
            />
          </div>
        )}

        {hideAddButton ? null : (
          <div>
            <ATMLoadingButton
              onClick={buttonProps?.onClick}
              className={buttonProps?.className}
            >
              {" "}
              {buttonProps?.btnName}{" "}
            </ATMLoadingButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ATMPageHeader;
