// ColorComponent.tsx

import React, { useEffect } from "react";

interface ColorComponentProps {
  inputString: string;
}

const ColorComponent: React.FC<ColorComponentProps> = ({ inputString }) => {
  useEffect(() => {
    // Logging character codes when the component mounts
    const lowercaseStr = inputString.toLowerCase().replace(/\s/g, "");
    for (let i = 0; i < lowercaseStr.length; i++) {
      // const charCode = lowercaseStr.charCodeAt(i);
      // console.log(`Character: ${lowercaseStr[i]}, Char Code: ${charCode}`);
    }
  }, [inputString]); // Empty dependency array to run only once

  const generateColorHash = (str: string): string => {
    const lowercaseStr = str.toLowerCase().replace(/\s/g, ""); // Convert to lowercase and remove spaces

    let charSum = 0;

    for (let i = 0; i < lowercaseStr.length; i++) {
      charSum += lowercaseStr.charCodeAt(i);
    }
    // console.log("charSum", charSum);

    // Add some randomization factor
    // const randomFactor = Math.floor(Math.random() * 100);
    // console.log("randomFactor", randomFactor);

    const r = charSum % 255;
    const g = (charSum * 0) % 255;
    const b = (charSum * 8) % 255;
    // console.log("r", r);
    // console.log("g", g);
    // console.log("b", b);

    const colorHash = `#${componentToHex(r)}${componentToHex(
      g
    )}${componentToHex(b)}`;

    // console.log("colorHash", colorHash);
    return colorHash;
  };

  const componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const color = generateColorHash(inputString);
  // console.log("color", color);

  return (
    <div style={{ color: color }} className={`bg-${color}`}>
      <p>{inputString}</p>
    </div>
  );
};

export default ColorComponent;
