import React, { useState } from "react";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import TicketListFilterFormDialogWrapper from "./TicketFilterFormCard/TicketListFilterFormDialogWrapper";
import ATMInputAdormant from "src/components/UI/atoms/formFields/ATMInputAdormant/ATMInputAdormant";
import { IoFilter } from "react-icons/io5";
import { BiSearch } from "react-icons/bi";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { useNavigate } from "react-router-dom";


type Props = {
  columns: columnTypes[];
  rows: any[];
  onAddNew: () => void;
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
  };
};
const TicketListing = ({
  columns,
  rows,
  onAddNew,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    setPage,
    setRowsPerPage,
    setSearchValue,
  },
}: Props) => {
  const [isOpenTicketFilterDialog, setIsOpenTicketFilterDialog] =
    useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the pressed keys match the specified shortcut (Ctrl + Shift + F)
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === "f") {
        // Open the filter dialog when the shortcut is detected
        setIsOpenTicketFilterDialog(true);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  return (
    <div className="flex flex-col h-full">
      {/* Page Header */}
      <div className="">
        <ATMPageHeader
          pageTitle="Tickets"
          hideSearchBox={true}
          hideAddButton={true}
        />
      </div>
      <div className="flex justify-between items-center py-3">
        <div className="flex gap-2 items-center">
          {/* Search Field */}
          <div className="w-[300px]">
            <ATMInputAdormant
              name=""
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              adormant={<BiSearch />}
              adormantProps={{
                position: "start",
                extraClasses: "bg-white border-0",
              }}
              inputProps={{ className: "bg-white" }}
              placeholder="Search..."
            />
          </div>

          {/* Filter Button */}
          <button
            type="button"
            onClick={() => setIsOpenTicketFilterDialog(true)}
            className={`rounded-lg flex justify-center items-center border border-gray-200 text-gray-600 shadow bg-gray-200 ${getInputHeight(
              "small"
            )} aspect-square`}
          >
            <IoFilter />
          </button>
          {/* Ticket Filter Dialog Wrapper */}
          <TicketListFilterFormDialogWrapper
            open={isOpenTicketFilterDialog}
            onClose={() => setIsOpenTicketFilterDialog(false)}
          />
        </div>

        {/* Sort By */}
        {/* <div className="flex gap-x-2">
            <MOLSortByDropdown
              options={sortByOptions}
              selected={sortValue}
              onSelect={(selected) => {
                const { label, ...rest } = selected;
                setSortValue(rest);
              }}
            />

           
          </div> */}
        <div>
          <ATMLoadingButton onClick={onAddNew}>Add New</ATMLoadingButton>
        </div>
      </div>
      {/* Table */}
      <div className="flex-1 overflow-auto flex flex-col">
        <ATMTable
          columns={columns}
          rows={rows}
          rowExtraClasses={() => "min-h-[40px]"}
          isLoading={isTableLoading}
          onRowClick={(row) => navigate(`ticket/${row?._id}/details`)}
        />
      </div>

      <div className="p-4 py-2">
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={rows}
        />
      </div>
    </div>
  );
};

export default TicketListing;
