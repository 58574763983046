import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MeetingListResponse } from "src/models/Meeting.model";
import {
  useApproveMomMutation,
  useGetMeetingByIdQuery,
  useGetMeetingNotesQuery,
} from "src/services/MeetingService";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import MeetingMOMTemplate from "./MeetingMOMTemplate";

type Props = {};

export type MeetingNotesTabFormValues = {
  meetingId: any;
  clientUserId: any;
  notes: any;
};

const MeetingMOMWrapper = (props: Props) => {
  const navigate = useNavigate();
  const [meetingNotes, setMeetingNotes] = useState<any[]>([]);

  const [meetingData, setMeetingData] = useState<MeetingListResponse>();

  const { meetingId: MEETING_ID } = useParams();
  const [apporoveMom] = useApproveMomMutation();

  const { isLoading, isFetching, data } = useGetMeetingByIdQuery(
    MEETING_ID || "",
    {
      skip: !MEETING_ID,
    }
  );

  const handleApproveMomClick = () => {
    showConfirmationDialog({
      title: "Are you sure?",
      text: "Want to approve MOM?",
      showCancelButton: true,
      next: (result) => {
        if (result?.isConfirmed) {
          apporoveMom(MEETING_ID).then((res: any) => {
            if (res?.error) {
              showToast("error", res?.error?.data?.message);
            } else {
              if (res?.data?.status) {
                showToast("success", res?.data?.message);
                navigate("/meeting");
              } else {
                showToast("error", res?.data?.message);
              }
            }
          });
        }
      },
    });
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setMeetingData(data?.data || []);
    }
  }, [isLoading, isFetching, data]);

  const {
    isLoading: isNotesLoading,
    isFetching: isNotesFetching,
    data: notesData,
  } = useGetMeetingNotesQuery(MEETING_ID);

  useEffect(() => {
    if (!isNotesLoading && !isNotesFetching) {
      setMeetingNotes(notesData?.data);
    }
  }, [isNotesLoading, isNotesFetching, notesData]);

  return isLoading || isFetching ? (
    <div className="absolute w-[80%] h-[100%] flex justify-center items-center z-10 ">
      <CircularProgress />
    </div>
  ) : (
    <MeetingMOMTemplate
      rows={meetingNotes || []}
      meetingData={meetingData}
      handleApproveMomClick={handleApproveMomClick}
    />
  );
};

export default MeetingMOMWrapper;
