import { IconType } from "react-icons";
import { AiOutlineSwitcher } from "react-icons/ai";
import { TbStatusChange } from "react-icons/tb";
import { GrResources } from "react-icons/gr";
import { GiDiscussion } from "react-icons/gi";

type NavItemTypeWithoutChild = {
  label: string;
  path: string;
  icon: IconType;
  children?: never;
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  children?: {
    label: string;
    path: string;
    icon: IconType;
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export const navigation: NavItemType[] = [
  {
    label: "Project",
    icon: AiOutlineSwitcher,
    path: "/project",
  },
  {
    label: "Change-Request",
    icon: TbStatusChange,
    path: "/change-request",
  },
  {
    label: "Activity",
    icon: TbStatusChange,
    path: "/activity",
  },
  {
    label: "Resource Request",
    icon: GrResources,
    path: "/resource-request",
  },
  {
    label: "Meeting",
    icon: GiDiscussion,
    path: "/meeting",
  }
];
