import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
// TODO: import { IoNotifications } from "react-icons/io5";
import NotificationCard from "./NotificationCard/NotificationCard";
import UserProfileCard from "./UserProfileCard/UserProfileCard";
import { Tooltip } from "@mui/material";
// import { IoNotifications } from "react-icons/io5";

interface Props {
  toggleCollapse: () => void;
  isCollapsed: boolean;
  entityName?: React.ReactNode;
}

const Header = ({ toggleCollapse, isCollapsed, entityName }: Props) => {
  const [isShowProfileCard, setIsShowProfileCard] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);

  //  const [isNewNotificationsAvailable, setIsNewNotificationsAvailable] =
  //  useState(false);

  const userData = localStorage.getItem("userData") !== "undefined"
    ? JSON.parse(localStorage.getItem("userData") || "{}")
    : "";

  return (
    <div className="grid grid-cols-2 w-full h-full border-b border-gray-300 bg-white px-4">
      {/* Menu Icon */}
      <div className="h-full flex items-center ">
        <Tooltip title="Alt + T">
          <button
            type="button"
            onClick={toggleCollapse}
            className={`text-xl text-slate-500 px-3 h-full  ${
              !isCollapsed && "md:hidden"
            }`}
          >
            <FiMenu />
          </button>
        </Tooltip>

        <div className="">{entityName}</div>
      </div>
      {/* Right Section */}
      <div className="flex gap-4 col-start-2 justify-end items-center h-full  ">
        {/* TODO: */}
         {/* <button
          onClick={() => {
            setIsShowNotification((isShowNotification) => !isShowNotification);
            setIsNewNotificationsAvailable(false);
          }}
          className="relative text-lg text-slate-700 transition-all duration-[800ms] hover:bg-slate-200 px-3 rounded-full"
        >
          <IoNotifications className="" />
          {isNewNotificationsAvailable ? (
            <span className="flex h-[7px] w-[7px] absolute -top-[1px] right-[11px]">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-100"></span>
              <span className="relative inline-flex rounded-full h-[7px] w-[7px] bg-red-600"></span>
            </span>
          ) : null}
        </button> */}
        <button
          onClick={() =>
            setIsShowProfileCard((isShowProfileCard) => !isShowProfileCard)
          }
          className="flex gap-5"
        >
          <div className="h-[28px] w-[28px] flex justify-center items-center font-bold bg-primary-main text-white text-[12px]  rounded-full">
            {userData?.name?.[0]?.toUpperCase() || ""}
          </div>
        </button>
        {isShowProfileCard && (
          <UserProfileCard
            onClickAway={() => setIsShowProfileCard(false)}
            userData={userData}
          />
        )}
        {isShowNotification && (
          <NotificationCard onClickAway={() => setIsShowNotification(false)} />
        )}
      </div>
    </div>
  );
};

export default Header;
