import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ActivityDateSliceStateType = {
  dateItems: any[] | [];
  dateTotalItems: number;
  dateIsTableLoading: boolean;
  datePage: number;
  dateRowsPerPage: number;
  dateSearchValue: string;
  dateSortValue: { field: string; value: "DESC" | "ASC" };
  dateFilterBy: {
    fieldName: string;
    value: string[];
  }[];
  dateDateFilter: {
    start_date: string | null;
    end_date: string | null;
  };
  activityPage: number;
  activityTotalItems: number;
};

const initialState: ActivityDateSliceStateType = {
  dateItems: [],
  dateTotalItems: 0,
  dateIsTableLoading: true,
  datePage: 1,
  dateRowsPerPage: 20,
  dateSearchValue: "",
  dateSortValue: { field: "createdAt", value: "DESC" },
  dateFilterBy: [
    {
      fieldName: "companyName",
      value: [],
    },
    {
      fieldName: "country",
      value: [],
    },
  ],
  dateDateFilter: {
    start_date: null,
    end_date: null,
  },
  activityPage: 1,
  activityTotalItems: 0,
};

const activityDateSlice: Slice<ActivityDateSliceStateType> = createSlice({
  name: "activityDateSlice",
  initialState,
  reducers: {
    setDateItems: (state, action: PayloadAction<any[] | []>) => {
      state.dateItems = action.payload;
    },
    setDatePage: (state, action: PayloadAction<number>) => {
      state.datePage = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setDateRowsPerPage: (state, action: PayloadAction<number>) => {
      state.dateRowsPerPage = action.payload;
      state.datePage = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setDateSearchValue: (state, action: PayloadAction<string>) => {
      state.dateSearchValue = action.payload;
      state.datePage = 1;
    },
    setDateSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.dateSortValue = action.payload;
      state.datePage = 1;
    },
    setDateTotalItems: (state, action: PayloadAction<number>) => {
      state.dateTotalItems = action.payload;
    },
    setDateIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.dateIsTableLoading = action.payload;
    },
    setDateFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.dateFilterBy = action.payload;
      state.datePage = 1;
    },
    setDateDateFilter: (
      state,
      action: PayloadAction<{ start_date: string; end_date: string }>
    ) => {
      state.dateDateFilter = action.payload;
    },
    setActivityPage: (state, action: PayloadAction<number>) => {
      state.activityPage = action.payload;
    },
    setActivityTotalItems: (state, action: PayloadAction<number>) => {
      state.activityTotalItems = action.payload;
    },
  },
});

export const {
  setDateItems,
  setDatePage,
  setDateRowsPerPage,
  setDateSearchValue,
  setDateSortValue,
  setDateTotalItems,
  setDateIsTableLoading,
  setDateDateFilter,
  setDateFilterBy,
} = activityDateSlice.actions;
export default activityDateSlice.reducer;
