import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import TicketsView from "./TicketsView";
import TicketsDownloadView from "./TicketDownloadView";

type Props = {
  getAllTicketData: any;
  changeRequestDetails: any;
};
const TicketsViewTemplate = ({
  getAllTicketData,
  changeRequestDetails,
}: Props) => {
  const changeRequestRef = useRef(null);
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef<any>(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    if (isAccordionOpen && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef?.current();
    }
  }, [isAccordionOpen]);
  const handlePrint = useReactToPrint({
    content: () => changeRequestRef?.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsAccordionOpen(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsAccordionOpen(false);
    },
    documentTitle: `${changeRequestDetails?.requestName}(Codiotic-Connect)`,
  });

  return (
    <div className="h-screen w-screen flex justify-center ">
      <div className="xxl:w-[70%] xl:w-[80%] h-full  ">
        <div className="flex justify-end py-3">
          <ATMLoadingButton className=" w-[10%]" onClick={handlePrint}>
            Download
          </ATMLoadingButton>
        </div>
        <div className="hidden">
          <TicketsDownloadView
            ref={changeRequestRef}
            getAllTicketData={getAllTicketData}
            changeRequestDetails={changeRequestDetails}
          />
        </div>
        <TicketsView
          getAllTicketData={getAllTicketData}
          changeRequestDetails={changeRequestDetails}
        />
      </div>
    </div>
  );
};

export default TicketsViewTemplate;
