/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import moment from "moment";
import { object, string, date } from "yup";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import TicketListFilterFormDialog, { ticketStatusOptions } from "./TicketListFilterFormDialog";
import { setDateFilter, setFilterBy, setCreatedByFilter } from "src/redux/slices/TicketSlice";

type Props = {
  open: boolean;
  onClose: () => void;
};

export type FormInitialValues = {
  startDate: string | null;
  endDate: string | null;
  priority: any;
  type: any;
  clientSideTicketStatus: any;
  createdBy: any,
  isArchived:boolean
};

const TicketListFilterFormDialogWrapper = ({ open, onClose }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const ticketState = useSelector((state: RootState) => state.ticket);
  const { dateFilter, filterBy, createdBy } = ticketState;
  const initialValues: FormInitialValues = {
    startDate: dateFilter?.startDate,
    endDate: dateFilter?.endDate,
    priority:
      filterBy?.find((filter: any) => filter.fieldName === "priority")?.value ||
      [],
    type:
      filterBy?.find((filter: any) => filter.fieldName === "type")?.value || [],
    clientSideTicketStatus: ticketStatusOptions?.filter((statusOptions) => {
      return (filterBy?.find(
        (filter: any) => filter.fieldName === "clientSideTicketStatus"
      )?.value || [])?.includes(statusOptions?.value)
    }),
    createdBy: createdBy,
    isArchived: filterBy?.find((filter: any) => filter.fieldName === "isArchived")?.value
  };

  const validationSchema: any = object({
    startDate: string().nullable(),
    endDate: string().nullable(),
  });

  // Submit Handler
  const handleSubmit = async (
    values: FormInitialValues,
    { setSubmitting, resetForm }: FormikHelpers<FormInitialValues>
  ) => {
    setSubmitting(false);

    // created date from to dispatch
    dispatch(
      setDateFilter({
        startDate: values.startDate
          ? moment(values.startDate)?.format("yyyy-MM-DD")
          : "",
        endDate: values.endDate
          ? moment(values.endDate)?.format("yyyy-MM-DD")
          : "",
      })
    );
    // createdBy filter dispatch
    dispatch(setCreatedByFilter(values.createdBy));

    await dispatch(
      setFilterBy(
        filterBy?.map((filter: any) => {
          switch (filter.fieldName) {
            case "priority":
              return {
                ...filter,
                value: values?.priority ? values?.priority : [],
              };
            case "type":
              return {
                ...filter,
                value: values?.type ? values?.type : [],
              };
            case "isArchived":
              return {
                ...filter,
                value: values?.isArchived ? values?.isArchived : false,
              };
            case "clientSideTicketStatus":
              return {
                ...filter,
                value: values?.clientSideTicketStatus
                  ? values?.clientSideTicketStatus?.map((status: any) => status?.value)
                  : [],
              };

            default:
              return filter;
          }
        })
      )
    );
    onClose();
  };

  // Reset Handler
  const handleReset = async (formikProps: FormikProps<FormInitialValues>) => {
    // created date filter dispatch
    await dispatch(
      setDateFilter({
        startDate: "",
        endDate: "",
      })
    );
    await dispatch(setCreatedByFilter([]));

    await dispatch(
      setFilterBy(
        filterBy?.map((filter: any) => {
          switch (filter.fieldName) {
            case "priority":
              return {
                ...filter,
                value: [],
              };
            case "type":
              return {
                ...filter,
                value: [],
              };
            case "clientSideTicketStatus":
              return {
                ...filter,
                value: [],
              };
            case "createdBy":
              return {
                ...filter,
                value: [],
              };
            case "isArchived":
              return {
                ...filter,
                value: false,
              };

            default:
              return filter;
          }
        })
      )
    );
    // reset formik props
    formikProps.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form>
          <TicketListFilterFormDialog
            open={open}
            onClose={onClose}
            formikProps={formikProps}
            onReset={() => handleReset(formikProps)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TicketListFilterFormDialogWrapper;
