import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetByIdChangeRequestQuery } from "src/services/ChangeRequestService";
import { useGetAllTicketWithoutPaginationQuery } from "src/services/TicketService";
import TicketsViewTemplate from "./TicketsViewTemplate";

const TicketsViewWrapper = () => {
  //USESTATE
  const [getAllTicketData, setGetAllTicketData] = useState<any>([]);
  const [changeRequestDetails, setChangeRequestDetails] = useState<any>(null);

  const { changeRequestId } = useParams();

  const {
    data: changeRequestData,
    isLoading: isChangeRequestDetailsLoading,
    isFetching: isChangeRequestDetailsFetching,
  } = useGetByIdChangeRequestQuery(changeRequestId, {
    skip: !changeRequestId,
  });

  const {
    data: ticketsData,
    isLoading: isLoadingTicketData,
    isFetching: isFetchingTicketData,
  } = useGetAllTicketWithoutPaginationQuery({
    limit: 10,
    searchValue: "",
    params: ["title"],
    page: 1,
    filterBy: [
      {
        fieldName: "changeRequestId",
        value: changeRequestId ? [changeRequestId] : [],
      },
    ],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: false,
  });

  useEffect(() => {
    if (!isFetchingTicketData && !isLoadingTicketData) {
      setGetAllTicketData(ticketsData?.data);
    }
  }, [isFetchingTicketData, isLoadingTicketData, ticketsData]);

  useEffect(() => {
    if (!isChangeRequestDetailsLoading && !isChangeRequestDetailsFetching) {
      setChangeRequestDetails(changeRequestData?.data);
    }
  }, [
    isChangeRequestDetailsLoading,
    isChangeRequestDetailsFetching,
    changeRequestData,
  ]);

  if (
    isLoadingTicketData ||
    isFetchingTicketData ||
    isChangeRequestDetailsFetching ||
    isChangeRequestDetailsLoading
  ) {
    return (
      <Backdrop open>
        <CircularProgress size={50} />
      </Backdrop>
    );
  }

  return (
    <TicketsViewTemplate
      getAllTicketData={getAllTicketData}
      changeRequestDetails={changeRequestDetails}
    />
  );
};

export default TicketsViewWrapper;
