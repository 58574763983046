import React from "react";
import { useNavigate } from "react-router-dom";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable, {
  columnTypes,
} from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetAllProjects } from "src/hooks/useGetAllProject";

type Props = {
  columns: columnTypes[];
  rows: any[];
  paginationProps: {
    isTableLoading: boolean;
    totalItems: number;
    page: number;
    rowsPerPage: number;
    searchValue: string;
    filterBy: any[];
    setPage: (newPage: number) => void;
    setRowsPerPage: (newLimit: number) => void;
    setSearchValue: (newValue: string) => void;
    setFilterBy: (newValue: any[]) => void;
  };
};

const ChangeRequestListing = ({
  columns,
  rows,
  paginationProps: {
    isTableLoading,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    filterBy,
    setPage,
    setRowsPerPage,
    setSearchValue,
    setFilterBy,
  },
}: Props) => {
  const navigate = useNavigate();
  const { allProjects, isDataLoading } = useGetAllProjects();

  const getFormattedProjectsOption = (projects: any[]) => {
    const formattedProjects = projects?.map((project) => ({
      label: project?.projectName,
      value: project?._id,
    }));

    return [...formattedProjects, { label: "All", value: "ALL" }];
  };

  const handleProjectChange = (projectId: any) => {
    const newFilterBy = filterBy?.map((filter) => {
      if (filter?.fieldName === "projectId") {
        return {
          ...filter,
          value: projectId === "ALL" ? [] : [projectId],
        };
      } else {
        return filter;
      }
    });

    setFilterBy(newFilterBy);
  };

  return (
    <div className="flex flex-col h-full p-4">
      {/* Page Header */}
      <div className="p-4 flex gap-4">
        <div className="flex-1">
          <ATMPageHeader
            pageTitle="Change Requests"
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            hideAddButton
          />
        </div>

        <div>
          {/* Project */}
          <div className="">
            <ATMSelect
              name=""
              value={
                getFormattedProjectsOption(allProjects)?.find(
                  (project) =>
                    project.value ===
                    filterBy?.find(
                      (filter) => filter?.fieldName === "projectId"
                    )?.value?.[0]
                ) || { label: "All", value: "ALL" }
              }
              onChange={(newValue) => handleProjectChange(newValue?.value)}
              placeholder="Select Project"
              options={getFormattedProjectsOption(allProjects)}
              renderOption={(option, { context }) => {
                if (context === "menu") {
                  return <div className="capitalize">{option?.label}</div>;
                } else {
                  return <div className="capitalize">{option?.label}</div>;
                }
              }}
              isLoading={isDataLoading}
              menuPosition="absolute"
            />
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="flex-1 overflow-auto flex flex-col">
        <ATMTable
          columns={columns}
          rows={rows}
          rowExtraClasses={() => "min-h-[40px]"}
          onRowClick={(row) => navigate(`/change-request/${row?._id}`)}
          isLoading={isTableLoading}
        />
      </div>

      <div className="p-4 py-2 flex justify-end">
        <ATMPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          rowCount={totalItems}
          rows={rows}
        />
      </div>
    </div>
  );
};

export default ChangeRequestListing;
