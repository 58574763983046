import React, { useState, useEffect } from "react";
import ATMSelect from "../../atoms/formFields/ATMSelect/ATMSelect";
import { useGetFeaturesQuery } from "src/services/FeatureServices";

type Props = {
  name?: string;
  value: any;
  onChange: (newValue: any) => void;
  onBlur?: (e: any) => void;
  label?: string;
  placeholder?: string;
};

const MOLFeatureDropdown = ({
  name = "",
  value,
  onChange,
  onBlur,
  label = "Feature",
  placeholder = "Select Feature",
}: Props) => {
  const [featuresOptions, setFeatureOption] = useState([]);

  const { data, isLoading, isFetching } = useGetFeaturesQuery("");

  // Setting Team Members Data
  useEffect(() => {
    if (!isLoading && !isFetching) {
      setFeatureOption(data?.data || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <ATMSelect
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      placeholder={placeholder}
      options={featuresOptions}
      isLoading={isLoading || isFetching}
      renderOption={(option, { context }) => {
        if (context === "menu") {
          return <div className="capitalize"> {option.featureName} </div>;
        } else {
          return <div className="capitalize"> {option.featureName} </div>;
        }
      }}
      isOptionEqualToValue={(option, selected) => {
        return option?._id === selected?.[0]?._id;
      }}
      isSearchedOption={(option, searchValue) => {
        return searchValue
          ? option?.data?.featureName?.toLowerCase()?.includes(searchValue)
          : true;
      }}
    />
  );
};

export default MOLFeatureDropdown;
