/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDateTotalItems } from "src/redux/slices/ActivityDateSlice";
import { RootState } from "src/redux/store";
import {
  useGetAllActivityDatesQuery,
  useGetAllActivityMutation,
} from "src/services/ActivityService";
import ActivityDateFilterFormDialogWrapper from "../Dialog/ActivityDateFilterDialog/ActivityDateFilterDialogFormWrapper";
import ActivityListing from "./ActivityListing";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";

type Props = {};

const ActivityListingWrapper = (props: Props) => {
  //State
  const dispatch = useDispatch();
  const [activityData, setActivityData] = useState<any>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // Activity Date
  const { datePage } = useSelector((state: RootState) => state.activityDate);
  const { activityDateFilter } = useSelector(
    (state: RootState) => state.activity
  );

  //API
  const [getAllActivity, getAllActivityInfo] = useGetAllActivityMutation();
  const {
    data: dateData,
    isLoading: dateDataIsLoading,
    isFetching: dateDataIsFetching,
  } = useGetAllActivityDatesQuery({
    limit: 5,
    page: datePage,
    dateFilter: {
      startDate: "",
      endDate: "",
    },
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!dateDataIsLoading && !dateDataIsFetching) {
      dispatch(setDateTotalItems(dateData?.totalItem));
    }
  }, [dateDataIsLoading, dateDataIsFetching, dateData]);

  useEffect(() => {
    if (!dateDataIsFetching && !dateDataIsLoading) {
      let isDateFilterExist = Object.values(activityDateFilter)?.every(
        (date: any) => date
      );
      let dateArray = isDateFilterExist
        ? [
            activityDateFilter?.startDate,
            activityDateFilter?.endDate || activityDateFilter?.startDate,
          ]
        : dateData?.data?.[0]?.dates;
      dateArray?.map((date: any) => {
        return getAllActivity({
          limit: 5,
          searchValue: "",
          params: ["title"],
          page: 1,
          filterBy: [],
          dateFilter: {
            startDate: date || "",
            endDate: date || "",
          },
          orderBy: "createdAt",
          orderByValue: -1,
          isPaginationRequired: false,
        }).then((res: any) => {
          if (res?.data?.status) {
            const ticketInfo = {
              date: new Date(date),
              ticketData: res?.data?.data,
            };
            setActivityData((prev: any) => [...prev, ticketInfo]);
          }
        });
      });
    }
  }, [dateDataIsFetching, dateDataIsLoading, dateData, activityDateFilter]);

  return (
    <>
      <SideNavLayout>
        {(dateDataIsFetching ||
          dateDataIsLoading ||
          getAllActivityInfo?.isLoading) &&
        !activityData?.length ? (
          <div className="flex justify-center items-center h-[80vh]">
            <CircularProgress size={50} />
          </div>
        ) : (
          <ActivityListing
            datesData={activityData?.sort((a: any, b: any) => {
              return b.date - a.date;
            })}
            paginationProps={{
              onFilterClick: () => setIsFilterOpen(true),
            }}
          />
        )}
      </SideNavLayout>
      {isFilterOpen && (
        <ActivityDateFilterFormDialogWrapper
          open={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
        />
      )}
    </>
  );
};

export default ActivityListingWrapper;
