import React from "react";
// |-- External Dependencies --|
import Select, {
  FormatOptionLabelMeta,
  GroupBase,
  StylesConfig,
} from "react-select";
import { ErrorMessage } from "formik";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
import makeAnimated from "react-select/animated";

export type Option = {
  label: string;
  value: string | number | string[];
};

type Props = {
  options: Option[] | any[];
  value?: Option[] | any[];
  onChange: (value: any) => void;
  isOptionEqualToValue?: (option: any, selected: any) => boolean;
  isSearchedOption?: (option: any, searchValue: string) => boolean;
  getOptionValue?: (option: any) => string;
  label?: string;
  required?: boolean;
  size?: "small" | "medium" | number;
  name: string;
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  renderOption?: (
    option: Option | any,
    meta: FormatOptionLabelMeta<Option | any>
  ) => React.ReactNode;
  onBlur?: any;
};

const ATMMultiSelect = ({
  options,
  label = "",
  required = false,
  value,
  placeholder = `Select`,
  onChange,
  size = "small",
  name,
  isLoading = false,
  disabled = false,
  renderOption,
  isOptionEqualToValue,
  getOptionValue,
  isSearchedOption,
  onBlur,
}: Props) => {
  const animatedComponents = makeAnimated();

  const customStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
    option: (styles:any, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "var(--surface-dark)"
          : isFocused
          ? "var(--surface)"
          : undefined,

        color: isDisabled
          ? undefined
          : isSelected
          ? "black"
          : isFocused
          ? undefined
          : undefined,
      };
    },

    menu: (styles:any) => {
      return {
        ...styles,
        minWidth: 250,
        zIndex: 10000,
      };
    },

    container: (styles:any, { isFocused }) => {
      return {
        ...styles,
        minWidth: 250,
        width: "100%",
        height: getInputHeight(size, true),
      };
    },

    control: (styles:any, { isFocused }) => {
      return {
        ...styles,
        height: "100%",
        border: isFocused ? "2px solid var(--primary-dark)" : undefined,
        boxShadow: "none",
      };
    },

    valueContainer: (styles:any) => ({
      ...styles,
      border: "none",
      flexWrap: "nowrap",
      overflow: "scroll",
    }),

    multiValue: (styles:any) => ({
      ...styles,
      display: "flex",
    }),

    multiValueLabel: (styles:any) => ({
      ...styles,
      flex: 1,
    }),

    // Add any custom styles here if needed
  };

  return (
    <div className="relative bg-inherit">
      {label && (
        <label
          className={`text-slate-700 font-medium text-sm ${
            disabled && "opacity-40"
          } `}
        >
          {label} {required && <span className="text-red-500"> * </span>}
        </label>
      )}
      <Select
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        isOptionSelected={isOptionEqualToValue}
        styles={customStyles}
        isMulti
        isDisabled={disabled}
        isClearable
        isLoading={isLoading}
        placeholder={placeholder}
        className="border-1 border-divider rounded mt-1"
        menuPosition="fixed"
        formatOptionLabel={renderOption}
        components={animatedComponents}
        classNamePrefix="custom-multi-select"
        getOptionValue={getOptionValue}
        filterOption={
          isSearchedOption
            ? (option, inputValue) =>
                isSearchedOption?.(option, inputValue?.toLowerCase())
            : undefined
        }
        onBlur={onBlur}
      />
      {name && (
        <ErrorMessage name={name}>
          {(errMsg) => (
            <p className="font-poppins absolute text-[14px] text-start mt-0 text-red-500">
              {errMsg}
            </p>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMMultiSelect;
