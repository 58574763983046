import React, { ReactNode } from "react";
import { ErrorMessage } from "formik";
import Select, {
  StylesConfig,
  GroupBase,
  FormatOptionLabelMeta,
  Options,
  PropsValue,
} from "react-select";
import { getInputHeight } from "src/utils/formUtils/getInputHeight";
type Option = {
  value: string;
  label: string;
};

type Props = {
  label?: string;
  required?: boolean;
  size?: "small" | "medium";
  name: string;
  isLoading?: boolean;
  searchable?: boolean;
  placeholder?: string;
  extraClasses?: string;
  renderValue?: (selected: any) => ReactNode;
  getValue?: (selected: any) => any;
  isOptionEqualToValue?: (option: any, selected: any) => boolean;
  isSearchedOption?: (option: any, searchValue: string) => boolean;
  showAddButton?: boolean;
  onAddNew?: (formValues: any, closeForm: () => void) => void;
  readonly?: boolean;
  onBlur?: any;

  options: Options<Option | any>;
  onChange: (selectedOption: Option | any) => void;
  renderOption?: (
    option: Option | any,
    meta: FormatOptionLabelMeta<Option | any>
  ) => React.ReactNode;
  disabled?: boolean;
  value: PropsValue<Option | any>;
  isOptionSelected?: (option: any, selectedValue: any) => boolean;
  menuPlacement?: "auto" | "top" | "bottom";
  menuPosition?: "fixed" | "absolute";
  isMulti?: boolean;
  
};

const ATMSelect = ({
  label = "",
  name,
  options,
  value,
  onChange,
  required = false,
  renderOption,
  isLoading = false,
  isOptionEqualToValue,
  disabled = false,
  menuPlacement = "bottom",
  isSearchedOption,
  placeholder = "Select",
  size = "small",
  onBlur,
  menuPosition = "fixed",
  isMulti = false,
  searchable = false,
  extraClasses,
  renderValue = (selected) => selected?.label,
  getValue = (selected) => selected?.value,
  showAddButton = false,
  onAddNew,
  readonly = false,
}: Props) => {
  const customStyles: StylesConfig<Option, false, GroupBase<Option>> = {
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "var(--surface-dark)"
          : isFocused
          ? "var(--surface)"
          : undefined,

        color: isDisabled
          ? undefined
          : isSelected
          ? "black"
          : isFocused
          ? undefined
          : undefined,
      };
    },

    menu: (styles: any) => {
      return {
        ...styles,
        minWidth: 250,
        zIndex: 10000,
      };
    },

    container: (styles: any, { isFocused }: any) => {
      return {
        ...styles,
        minWidth: 250,
        height: getInputHeight(size, true),
      };
    },

    control: (styles: any, { isFocused }: any) => {
      return {
        ...styles,
        height: "100%",
        border: isFocused ? "2px solid var(--primary-dark)" : undefined,
        boxShadow: "none",
      };
    },

    // Add any custom styles here if needed
  };

  return (
    <>
      <div className="relative bg-inherit " id="select-container">
        {label && (
          <label
            className={`text-slate-700 font-medium text-sm ${
              disabled && "opacity-40"
            } `}
          >
            {label} {required && <span className="text-red-500"> * </span>}
          </label>
        )}

        <Select
          isMulti={false}
          options={options}
          value={value}
          onChange={(option: any) => onChange(option)}
          styles={customStyles}
          formatOptionLabel={renderOption}
          isDisabled={disabled}
          isOptionSelected={isOptionEqualToValue}
          menuPlacement={menuPlacement}
          isLoading={isLoading}
          placeholder={placeholder}
          filterOption={
            isSearchedOption
              ? (option: any, inputValue: any) =>
                  isSearchedOption?.(option, inputValue?.toLowerCase())
              : undefined
          }
          className="border-1 border-divider rounded"
          onBlur={onBlur}
          menuPosition={menuPosition}
        />

        {name && (
          <ErrorMessage name={name}>
            {(errMsg) => (
              <p className="font-poppins absolute text-[14px] text-start mt-0 text-red-500">
                {errMsg}
              </p>
            )}
          </ErrorMessage>
        )}
      </div>
    </>
  );
};
export default ATMSelect;
