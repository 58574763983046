import React from "react";

// Redux
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import { RiMapPinUserFill } from "react-icons/ri";
import { FaStopwatch } from "react-icons/fa";

type Props = {};


const CloseTickets = (props: Props) => {
    // Get the Ticket id

    // redux state
    const { singleItem } = useSelector((state: RootState) => state.ticket);

    const timeTakenAsString = singleItem[0]?.timeTaken;
    const timeTakenAsNumber = Number(timeTakenAsString || 0);
    return (
        <div className="p-4 flex flex-col gap-4 overflow-auto">
            {/* Problem Statement */}
            <div className="grid grid-cols-2">
                <div className="shadow-md p-3 flex justify-between items-center rounded-md mx-3 my-2" >
                    <div className="flex gap-2">
                        <div className="border p-2 rounded-full">
                            <RiMapPinUserFill size={16} className="rounded-full mx-auto" />
                        </div>
                        <span className="text-xl text-primary-main font-semibold">
                            Developers Involved
                        </span>
                    </div>

                    <div className="text-primary-main font-semibold">
                        {singleItem[0]?.numberOfDeveloper || 0}
                        {/* {`${el.durationInMinutes?.toFixed()} min (${Math.floor(el.durationInMinutes / 60)}:${(el.durationInMinutes % 60)?.toFixed()} hrs)`} */}
                    </div>
                </div>

                <div className="shadow-md p-3 flex justify-between items-center rounded-md mx-3 my-2" >
                    <div className="flex gap-2">
                        <div className="border p-2 rounded-full">
                            <FaStopwatch size={16} className="rounded-full mx-auto" />
                        </div>
                        <span className="text-xl text-primary-main font-semibold">
                            Time Taken
                        </span>
                    </div>

                    <div className="text-primary-main font-semibold">
                        {/* {singleItem[0]?.timeTaken || 0} */}
                        {timeTakenAsNumber ?
                            <div>
                                {`${timeTakenAsNumber?.toFixed()} min (${Math.floor(timeTakenAsNumber / 60 || 0)}:${(timeTakenAsNumber % 60 || 0)?.toFixed()} hrs)`}
                            </div>
                            : 0}
                    </div>
                </div>
            </div>
            <div className="shadow-md p-3 flex flex-col   rounded-md mx-3 my-2" >
                <div className="flex gap-2">
                    <span className="text-xl text-primary-main font-semibold">
                        Remark
                    </span>
                </div>

                <div className="text-primary-main font-semibold">
                    {singleItem[0]?.remark || '-'}
                </div>
            </div>
            {singleItem[0]?.origin ?
                <div className="shadow-md p-3 flex flex-col   rounded-md mx-3 my-2" >
                    <div className="flex gap-2">
                        <span className="text-xl text-primary-main font-semibold">
                            origin
                        </span>
                    </div>
                    <div className="text-primary-main font-semibold">
                        {singleItem[0]?.origin || '-'}
                    </div>
                </div>
                : null}
        </div>
    );
};

export default CloseTickets;
