export enum ENMTResourceRequestStatus {
  "PENDING" = "Pending",
  "CLOSED" = "Closed",
}

export type TYPResourceRequestStatus = keyof typeof ENMTResourceRequestStatus;

type Props = {
  status: TYPResourceRequestStatus;
};

const getClassNameByStatus = (status: TYPResourceRequestStatus) => {
  switch (status) {
    case "PENDING":
      return "text-[#000000] border-[#CCC] bg-[#FFFF001A]";

    case "CLOSED":
      return "text-red-500 bg-red-100 border-red-500";
  }
};

const MOLResourceRequestStatusChip = ({ status }: Props) => {
  const className = ` 
      font-medium
      rounded-full
      px-2
      py-[2px]
      border
      text-[14px]
      border-[1px]
      ${getClassNameByStatus(status)}`;

  return <div className={className}> {ENMTResourceRequestStatus[status]} </div>;
};

export default MOLResourceRequestStatusChip;
