import apiSlice from "./ApiSlice";

export const featureApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all feature with pagination
    getAllFeaturesWithPagination: builder.query({
      providesTags: ["features"],
      query: (body: any) => {
        return {
          url: "/feature",
          method: "POST",
          body,
        };
      },
    }),

    // Get all feature
    getFeatures: builder.query({
      providesTags: ["features"],
      query: () => {
        return {
          url: "/feature/app",
          method: "GET",
        };
      },
    }),

    // Add
    addFeature: builder.mutation({
      invalidatesTags: ["features"],
      query: (body) => {
        return {
          url: "/feature/add/app",
          method: "POST",
          body,
        };
      },
    }),

    //
  }),
});

export const {
  useGetAllFeaturesWithPaginationQuery,
  useGetFeaturesQuery,
  useAddFeatureMutation,
} = featureApi;
