import Dialog from "@mui/material/Dialog";
import { FormikProps } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMCheckbox from "src/components/UI/atoms/formFields/ATMCheckbox/ATMCheckbox";
import ATMDatePicker from "src/components/UI/atoms/formFields/ATMDatePicker/ATMDatePicker";
import ATMMultiSelect from "src/components/UI/atoms/formFields/ATMMultiSelect/ATMMultiSelect";
import ATMRadioButtonGroup from "src/components/UI/atoms/formFields/ATMRadioButtonGroup/ATMRadioButtonGroup";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import { useGetAllUsersQuery } from "src/services/TicketService";
import { ticketPriorites, ticketTypes } from "src/utils/constants/ticketUtils";
import { FormInitialValues } from "./TicketListFilterFormDialogWrapper";

type Props = {
  formikProps: FormikProps<FormInitialValues>;
  onReset: () => void;
  open: boolean;
  onClose: () => void;
};

export const ticketStatusOptions = [
  {
    label: "Open",
    value: "OPEN",
  },
  {
    label: "In Progress",
    value: "IN_PROGRESS",
  },
  {
    label: "Close",
    value: "CLOSE",
  },
];

const TicketListFilterFormDialog = ({
  open,
  formikProps,
  onReset,
  onClose,
}: Props) => {
  const { values, setFieldValue, isSubmitting, handleSubmit } = formikProps;
  const { clientId } = JSON.parse(localStorage.getItem("userData") || "{}");
  // created by
  const [clientUserOptions, setClientUserOptions] = useState([]);

  const {
    data: clientUserData,
    isLoading: isClientUserLoading,
    isFetching: isClientUserFetching,
  } = useGetAllUsersQuery(clientId);
  // Setting Team Members Data

  useEffect(() => {
    if (!isClientUserLoading && !isClientUserFetching) {
      const filterClientOptions = clientUserData?.data?.map((client: any) => ({
        label: client.name,
        value: client._id,
      }));
      setClientUserOptions(filterClientOptions || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUserData, isClientUserLoading, isClientUserFetching]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="flex flex-col gap-4 px-4 py-2">
        {/* Heading & Clear Button */}
        <div className="flex justify-between items-center sticky top-0 z-50">
          <div className="text-xl font-medium"> Filter </div>
          <button
            type="button"
            className="text-red-600 hover:text-red-400 font-medium"
            onClick={onReset}
          >
            Clear Filters
          </button>
        </div>

        {/* Filter Form Field */}
        {/* Priority */}
        <ATMRadioButtonGroup
          label="Priority"
          required={false}
          value={values.priority}
          options={ticketPriorites}
          allowMultipleSelection
          onChange={(newValue) => {
            setFieldValue("priority", newValue);
          }}
        />

        {/* Type */}
        <ATMRadioButtonGroup
          required={false}
          label="Type"
          allowMultipleSelection
          value={values.type}
          options={ticketTypes}
          onChange={(newValue) => setFieldValue("type", newValue)}
        />

        {/* Status */}
        <div>
          <ATMMultiSelect
            name="clientSideTicketStatus"
            value={values.clientSideTicketStatus}
            options={ticketStatusOptions}
            onChange={(newValue) =>
              setFieldValue("clientSideTicketStatus", newValue)
            }
            label="Status"
            placeholder="Select Status"
          />
        </div>

        {/* Created By */}
        <div className="">
          <ATMSelect
            label="Created by client user"
            name="createdBy"
            value={values.createdBy}
            options={clientUserOptions}
            placeholder="Select Created By"
            onChange={(newValue) => {
              setFieldValue("createdBy", newValue);
            }}
          />
        </div>

        {/* Created At */}
        <div className="mb-2">
          <div className="text-slate-500 font-medium text-sm"> Created At </div>
          <div className="flex gap-2 items-center">
            {/* From */}
            <div className="w-full">
              <ATMDatePicker
                label="From"
                name="startDate"
                format="DD/MM/YYYY"
                maxDate={moment().format("yyyy-MM-DD")}
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
              />
            </div>

            {/* To */}
            <div className="w-full">
              <ATMDatePicker
                label="To"
                name="endDate"
                format="DD/MM/YYYY"
                value={values.endDate}
                minDate={values?.startDate || undefined}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
              />
            </div>
          </div>
        </div>

        {/* IsArchived */}
        <div className="">
          <ATMCheckbox
            label="Archived Tickets"
            name="isArchived"
            checked={values.isArchived}
            onChange={(checked) => {
              setFieldValue("isArchived", checked);
            }}
          />
        </div>

        {/* Apply & Cancel Buttons */}
        <div className="flex flex-col gap-2  sticky bottom-0 bg-white">
          <div>
            <ATMLoadingButton
              type="submit"
              className="h-[40px]"
              isLoading={isSubmitting}
              onClick={() => handleSubmit()}
            >
              Apply
            </ATMLoadingButton>
          </div>

          <div>
            <ATMLoadingButton
              className="bg-slate-300 hover:bg-gray-200 transition-all h-[40px] border-none text-slate-700 font-medium"
              onClick={onClose}
            >
              Cancel
            </ATMLoadingButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TicketListFilterFormDialog;
