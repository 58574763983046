import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { setAccessToken, setRefreshToken } from "src/redux/slices/AuthSlice";
import { refreshTokenKeyName } from "src/utils/configs/authConfig";

const LandingPage = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem(refreshTokenKeyName);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const clientRefreshToken = queryParams.get("refreshToken");
  useEffect(() => {
    if (clientRefreshToken) {
      localStorage.clear();
      dispatch(setRefreshToken(clientRefreshToken));
      dispatch(setAccessToken(""));
      localStorage.setItem(refreshTokenKeyName, clientRefreshToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRefreshToken]);

  if (!accessToken && !refreshToken && !clientRefreshToken) {
    return <Navigate to="/login" />;
  }

  return <Navigate to="/project" />;
};

export default LandingPage;
