import moment from "moment";
import { useEffect } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { MdAccessTime, MdOutlineCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import {
  setFilterBy,
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ChangeRequestSlice";
import { RootState } from "src/redux/store";
import { useGetAllChangeRequestWithPaginationQuery } from "src/services/ChangeRequestService";
import { getEstimationInDays } from "src/utils/constants/ticketUtils";
import ChangeRequestListing from "./ChangeRequestListing";

type Props = {};

export const renderChangeRequestApprovalStatus = (
  status: "RAISED" | "APPROVED"
) => {
  const statusWiseFormatting = {
    RAISED: {
      label: (
        <div className="flex gap-1 items-center">
          {" "}
          <MdAccessTime size={16} /> Waiting for you{" "}
        </div>
      ),
      className: "text-yellow-500",
    },
    APPROVED: {
      label: (
        <div className="flex gap-1 items-center">
          {" "}
          <BiCheckCircle size={16} /> Approved
        </div>
      ),
      className: "text-green-500",
    },
    DISAPPROVED: {
      label: (
        <div className="flex gap-1 items-center">
          {" "}
          <MdOutlineCancel size={16} /> Declined
        </div>
      ),
      className: "text-red-500",
    },
  };
  const classNames = `font-semibold text-base ${statusWiseFormatting[status]?.className}`;

  return (
    <div className={classNames}> {statusWiseFormatting[status]?.label} </div>
  );
};

const ChangeRequestListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    filterBy,
  } = useSelector((state: RootState) => state.changeRequest);

  const { data, isLoading, isFetching } =
    useGetAllChangeRequestWithPaginationQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["clientId", "requestName"],
      page: page,
      filterBy: filterBy,
      dateFilter: {},
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    });

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Date-Time",
      extraClasses: "min-w-[240px]",
      renderCell: (row: any) => (
        <div className="flex flex-wrap font-semibold text-slate-700">
          {row?.approvedOnDate || '-'}
          {/* <div className="font-semibold text-slate-700">
            {" "}
            {moment(row?.createdAt).format("DD MMM yyyy")}{" "}
          </div>
          <div className="font-semibold text-slate-500">
            {" "}
            {moment(row?.createdAt).format("hh:mm A")}{" "}
          </div> */}
        </div>
      ),
    },
    {
      field: "requestName",
      headerName: "Change-Request",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[170px]",
    },
    {
      field: "noOfTickets",
      headerName: "Total tickets",
      flex: "flex-[0.5_0.5_0%]",
      extraClasses: "font-medium min-w-[110px]",
    },
    {
      field: "projectName",
      headerName: "Project",
      extraClasses: "min-w-[180px]",
      renderCell: (row: any) => {
        return <span className="capitalize">{row?.projectName}</span>;
      },
    },
    {
      field: "timeEst",
      headerName: "Estimated Time",
      extraClasses: "min-w-[140px]",
      renderCell: (row) => {
        return (
          <div>
            {getEstimationInDays({
              time:
                Number(row?.devAndQaTime || 0) +
                Number(row?.deploymentTime?.timeSpent || 0) +
                Number(row?.demoTime?.timeSpent || 0),
              workingHours: row?.projectPerDayWorkingHour || 0,
            })}
          </div>
        );
      },
    },
    {
      field: "costEst",
      headerName: "Estimated Cost",
      extraClasses: "min-w-[140px]",
      renderCell: (row: any) => <div> &#8377; {row?.costEst || 0} </div>,
    },
    {
      field: "estDeliveryDate",
      headerName: "Est. Delivery Date",
      extraClasses: "min-w-[160px]",
      renderCell: (row) => {
        return (
          <span>
            {row?.estDeliveryDate && moment(row?.estDeliveryDate)?.isValid()
              ? moment(row?.estDeliveryDate)?.format("DD MMM yyyy")
              : "-"}
          </span>
        );
      },
    },
    {
      field: "approvalStatus",
      headerName: "Status",
      extraClasses: "min-w-[160px]",
      renderCell: (row) => {
        return renderChangeRequestApprovalStatus(row?.approvalStatus);
      },
    },
    {
      field: "approvalRemark",
      headerName: "Approval Remark",
      flex: "flex-[1_1_0%]",
      renderCell: (row) => row?.approvalRemark || "-",
    },
  ];

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  return (
    <SideNavLayout>
      <div>
        <ChangeRequestListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            filterBy,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
            setFilterBy: (newValue) => dispatch(setFilterBy(newValue)),
          }}
        />
      </div>
    </SideNavLayout>
  );
};

export default ChangeRequestListingWrapper;
