import apiSlice from './ApiSlice';

export const ChangeRequestServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL Pagination 
    getAllChangeRequestWithPagination: builder.query({
      providesTags: ["ticket"],
      query: (body) => {
        return {
          url: "/change-request/app",
          method: "POST",
          body,
        };
      },
    }),

    changeRequestApproveEtaRequest: builder.mutation({
      invalidatesTags: ["ticket"],
      query: ({ body, requestId }) => {
        return {
          url: `change-request/app/approve-eta-request/${requestId}`,
          method: "PUT",
          body,
        };
      },
    }),

    getByIdChangeRequest: builder.query({
      providesTags: ["ticket"],
      query: (id) => {
        return {
          url: `/change-request/app/${id}`,
          method: "GET",
        };
      },
    }),
    // get doubt count
    getChangeRequestCount: builder.query({
      providesTags: ["project"],
      query: () => {
        return {
          url: "/change-request/count/app",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetAllChangeRequestWithPaginationQuery, useChangeRequestApproveEtaRequestMutation, useGetByIdChangeRequestQuery, useGetChangeRequestCountQuery } = ChangeRequestServiceApi;