import moment from "moment";
import { useEffect } from "react";
import { GrResources } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ATMMenu from "src/components/UI/atoms/ATMMenu/ATMMenu";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import MOLResourceRequestStatusChip from "src/components/UI/molecules/MOLResourceRequestStatusChip/MOLResourceRequestStatusChip";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/ResourceRequestSlice";
import { RootState } from "src/redux/store";
import { useGetAllResourceRequestQuery } from "src/services/ResourceRequestService";
import ResourceRequestListing from "./ResourceRequestListing";
import { FaRegEye } from "react-icons/fa";
type Props = {};

const ResourceRequestListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.resourceRequest);
  const { data, isLoading, isFetching } = useGetAllResourceRequestQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "projectId",
      "resourceName",
      "description",
      "remark",
      "createdById",
      "createdByName",
      "requestedFromId",
      "requestedFromName",
      "requestedFromType",
      "status",
      "attachments",
    ],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          <div className="font-semibold text-slate-700">
            {" "}
            {moment(row?.createdAt).format("DD MMM yyyy")}{" "}
          </div>
          <div className="font-semibold text-slate-500">
            {" "}
            {moment(row?.createdAt).format("hh:mm A")}{" "}
          </div>
        </div>
      ),
    },
    {
      field: "resourceName",
      headerName: "Resource Name",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      extraClasses: "uppercase",
    },
    {
      field: "uploadedByName",
      headerName: "Uploaded By",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row) => (
        <div>
          {row?.uploadedByName ? <span>{row?.uploadedByName}</span> : "-"}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: "flex-[0.5_0.5_0%]",
      extraClasses: "",
      renderCell: (row: any) => (
        <div>
          <MOLResourceRequestStatusChip status={row?.status as any} />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: "flex-[1_1_0%]",
      extraClasses: "flex justify-center",
      renderCell: (row: any) => {
        const menuOptions = [
          {
            label: (
              <div className="flex gap-x-2 items-center h-3">
                <GrResources color="#000000" /> Add Resource
              </div>
            ),
            onClick: () => navigate(`/resource-request/${row?._id}/add`),
          },
        ]; 
         const closeMenuOptions =[
          {
            label: (
              <div className="flex gap-x-2 items-center rounded  h-3">
                <FaRegEye color="#000000" /> View Resource
              </div>
            ),
            onClick: () => navigate(`/resource-request/${row?._id}/view`),
          },
         ]
        return row?.status !== "CLOSED" ? (
          <ATMMenu options={menuOptions} orientation="vertical" />
        ) : (
          <ATMMenu options={closeMenuOptions} orientation="vertical" />
        );
      },
    },
  ];
  
  return (
    <div>
      <ResourceRequestListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default ResourceRequestListingWrapper;
