/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/TicketSlice";
import { RootState } from "src/redux/store";
import TicketListing from "src/screens/Tickets/List/TicketListing";

import moment from "moment";
import { MdEdit } from "react-icons/md";
import ATMMenu from "src/components/UI/atoms/ATMMenu/ATMMenu";
import MOLPriorityChip from "src/components/UI/molecules/MOLPriorityChip/MOLPriorityChip";
import MOLTicketStatusChip from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import MOLTicketTypeChip from "src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip";
import { useGetAllTicketWithoutPaginationQuery } from "src/services/TicketService";
import { useGetTotalUnreadMessageProjectQuery } from "src/services/ChatServices";
import { setProjecstUnreadMessage } from "src/redux/slices/ChatSlice";

type Props = {};

const ProjectTicketListingWrapper = (props: Props) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const {
    items,
    totalItems,
    page,
    rowsPerPage,
    searchValue,
    isTableLoading,
    filterBy,
    dateFilter,
    createdBy
  } = useSelector((state: RootState) => state.ticket);
  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useGetAllTicketWithoutPaginationQuery(
    {
      limit: rowsPerPage,
      searchValue: searchValue,
      params: ["title", "ticketNumber"],
      page: page,
      filterBy: [
        {
          fieldName: "projectId",
          value: projectId ? [projectId] : [],
        },
        ...filterBy,
        {
          fieldName: "createdBy",
          value: createdBy?.value,
        },
      ],
      dateFilter: {
        startDate: dateFilter?.startDate || "",
        endDate: dateFilter?.endDate || "",
      },
      orderBy: "createdAt",
      orderByValue: -1,
      isPaginationRequired: true,
    }
  );

  useEffect(() => {
    if (isLoading || isFetching) {
      dispatch(setIsTableLoading(true));
    } else {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "unReadMessages",
      headerName: "Unseen Messages",
      extraClasses: "min-w-[180px] max-w-[180px]",
      align: "center",
      renderCell: (row: any) =>
        row?.unReadMessages ? (
          <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white">
            {row?.unReadMessages}
          </div>
        ) : (
          "-"
        ),
    },
    {
      field: "ticketNumber",
      headerName: "#Ticket Id",
      extraClasses: "min-w-[170px]",
      renderCell: (row: any) => <span> #{row.ticketNumber} </span>,
    },
    {
      field: "createdAt",
      headerName: "Date-Time",
      extraClasses: "min-w-[120px]",
      renderCell: (row: any) => (
        <div>
          <div className="font-semibold text-slate-700">
            {" "}
            {moment(row?.createdAt).format("DD MMM yyyy")}{" "}
          </div>
          <div className="font-semibold text-slate-500">
            {" "}
            {moment(row?.createdAt).format("hh:mm A")}{" "}
          </div>
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: "flex-[2_2_0%]",
    },
    {
      field: "createdByLabel",
      headerName: "Created By",
      flex: "flex-[1_1_0%]",
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: "flex-[1_1_0%]",
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: any) => (
        <div>
          <MOLPriorityChip priority={row?.priority} />
        </div>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: "flex-[1.7_1.7_0%]",
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: any) => (
        <div className="flex justify-center">
          {row?.type === "" ? (
            "-"
          ) : (
            <MOLTicketTypeChip ticketType={row?.type} />
          )}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[180px] max-w-[180px]",
      renderCell: (row: any) => (
        <div>
          <MOLTicketStatusChip status={row?.clientSideTicketStatus as any} />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: "flex-[1_1_0%]",
      extraClasses: "flex justify-center",
      renderCell: (row: any) => {
        const menuOptions = [
          {
            label: (
              <div className="flex items-center gap-x-2">
                <MdEdit color="#000000" /> Edit
              </div>
            ),
            onClick: () =>
              navigate(`/project/${projectId}/ticket/${row?._id}/edit`),
          },
        ];
        return (row?.status as any) === "UNMAPPED" ? (
          <ATMMenu options={menuOptions} orientation="vertical" />
        ) : (
          <span>-</span>
        );
      },
    },
  ];

  const {
    isLoading: isTotalUnreadMessageLoading,
    isFetching: isTotalUnreadMessageFetching,
    data: totalUnreadProjectMessage,
  } = useGetTotalUnreadMessageProjectQuery("");

  useEffect(() => {
    if (!isTotalUnreadMessageLoading && !isTotalUnreadMessageFetching) {
      dispatch(setProjecstUnreadMessage(totalUnreadProjectMessage?.data));
    }
  }, [
    isTotalUnreadMessageLoading,
    isTotalUnreadMessageFetching,
    totalUnreadProjectMessage,
  ]);

  return (
    <>
      <div className="h-full">
        <TicketListing
          columns={columns}
          rows={items}
          paginationProps={{
            isTableLoading,
            totalItems,
            page,
            rowsPerPage,
            searchValue,
            setPage: (newPage) => dispatch(setPage(newPage)),
            setRowsPerPage: (newRowsPerPage) =>
              dispatch(setRowsPerPage(newRowsPerPage)),
            setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
          }}
          onAddNew={() => navigate(`ticket/add`)}
        />
      </div>
    </>
  );
};

export default ProjectTicketListingWrapper;
