import React, { ReactNode } from "react";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Popover } from "@mui/material";
import { BsFillPinAngleFill, BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { RiUnpinFill } from "react-icons/ri";

interface ActionPopupProps {
  isIconVertical?: boolean;
  iconColor?: string;
  iconSize?: string;
  handleOnAction: () => void;
  children?: ReactNode;
  handleViewActionButton?: () => void;
  handleEditActionButton?: () => void;
  handleMessagePinActionButton?: () => void;
  handleMessageUnPinActionButton?: () => void;
  handleDeleteActionButton?: () => void;
  handleCustomActionButton?: () => void;
  popUpIcon?: ReactNode;
  isView?: boolean;
  isDelete?: boolean;
  isEdit?: boolean;
  isPin?: boolean;
  unPin?: boolean;
  isCustomBtn?: boolean;
  customBtnText?: string;
  className?: string;
}

const ActionPopup: React.FC<ActionPopupProps> = ({
  isIconVertical,
  iconColor = "#000000",
  iconSize = 18,
  handleOnAction,
  children,
  handleViewActionButton,
  handleEditActionButton,
  handleMessagePinActionButton,
  handleMessageUnPinActionButton,
  handleDeleteActionButton,
  handleCustomActionButton,
  popUpIcon = <BsThreeDotsVertical color={"#000"} size={20} />,
  isView = false,
  isEdit = false,
  isPin = false,
  unPin = false,
  isDelete = false,
  isCustomBtn = false,
  customBtnText = "Button",
  className = "block w-full text-left px-4 py-2",
}) => {
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState: any) => (
          <div onClick={handleOnAction} className="h-fit flex items-center">
            <button
              {...bindTrigger(popupState)}
              className="font-bold transition-all duration-[600ms] rounded-full"
            >
              {popUpIcon}
            </button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: -25,
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="">
                {isView && (
                  <button
                    onClick={handleViewActionButton}
                    className={className}
                  >
                    View
                  </button>
                )}

                {isEdit && (
                  <button
                    onClick={handleEditActionButton}
                    className={className}
                  >
                    Edit
                  </button>
                )}

                {isPin && (
                  <button
                    onClick={popupState.close}
                    className="block w-full text-left  hover:bg-gray-100"
                  >
                    <div
                      className=" px-4 py-2 flex gap-x-4 items-center"
                      onClick={handleMessagePinActionButton}
                    >
                      <BsFillPinAngleFill /> Pin
                    </div>
                  </button>
                )}

                {unPin && (
                  <button
                    onClick={popupState.close}
                    className="block w-full text-left  hover:bg-gray-100"
                  >
                    <div
                      className="flex gap-x-4 items-center px-4 py-2"
                      onClick={handleMessageUnPinActionButton}
                    >
                      <RiUnpinFill /> Un-pin
                    </div>
                  </button>
                )}

                {children}
                {isCustomBtn && (
                  <button
                    onClick={popupState.close}
                    className="block w-full text-left  hover:bg-gray-100"
                  >
                    <div
                      className="block px-4 py-2"
                      onClick={handleCustomActionButton}
                    >
                      {customBtnText}
                    </div>
                  </button>
                )}

                {isDelete && (
                  <button
                    onClick={popupState.close}
                    className="block w-full text-left  hover:bg-gray-100"
                  >
                    <div
                      className="flex gap-x-4 items-center px-4 py-2"
                      onClick={handleDeleteActionButton}
                    >
                      <MdDelete /> Delete
                    </div>
                  </button>
                )}
              </div>
            </Popover>
          </div>
        )}
      </PopupState>
    </>
  );
};

export default ActionPopup;
