export const getFormattedEstimationTime = (timeInMinutes: number) => {
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;

  if (hours && minutes) {
    return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} mins`;
  } else if (hours && !minutes) {
    return `${hours} ${hours > 1 ? "hrs" : "hr"}`;
  } else if (!hours && minutes) {
    return `${minutes} ${hours > 1 ? "mins" : "min"}`;
  }
};
