import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { AddTicketFormValues, FILE_FORMAT } from "src/models/Ticket.model";
import {
  useEditTicketMutation,
  useGetSingleTicketsViaIdQuery,
} from "src/services/TicketService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import TicketForm, { TICKET_PRIORITY } from "../Layouts/TicketForm";

const EditTicketFormWrapper = () => {
  const { projectId, ticketId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isFetching } =
    useGetSingleTicketsViaIdQuery(ticketId);
  const [editTicket] = useEditTicketMutation();


  // Form Initial Values
  const initialValues: AddTicketFormValues = {
    projectId: data?.data?.[0]?.projectId || "",
    title: data?.data?.[0]?.title || "",
    priority:
      TICKET_PRIORITY?.find(
        (priority) => priority?.value === data?.data?.[0]?.priority
      ) || "",
    description: data?.data?.[0]?.description || "",
    attachments: data?.data?.[0]?.attachments || [],
  };

  // Validation Schema
  const validationSchema = object().shape({
    projectId: string().required("Please enter project"),
    title: string().required("Please enter title"),
    priority: object().required("Please enter priority"),
  });

  // Handle Submit
  const handleSubmit = (
    values: AddTicketFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddTicketFormValues>
  ) => {
    const formatedValues = {
      ...values,
      priority: values?.priority?.value,
      attachments: values?.attachments?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
    };

    editTicket({
      ticketId: ticketId,
      body: formatedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
        setSubmitting(false);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          setSubmitting(false);
          navigate(`/project/${projectId}`);
        } else {
          showToast("error", res?.data?.message);
          setSubmitting(false);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <SideNavLayout>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form className="h-full">
            <div className="flex justify-center ">
              {isLoading || isFetching ? (
                <div className="absolute w-[80%] h-[100%] flex justify-center items-center z-10 ">
                  <CircularProgress />
                </div>
              ) : (
                <div className="w-[90%]">
                  <TicketForm
                    formikProps={formikProps}
                    onClose={() => navigate(`/project/${projectId}`)}
                    formType="Edit"
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </SideNavLayout>
  );
};

export default EditTicketFormWrapper;
