import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { setAccessToken, setRefreshToken } from "src/redux/slices/AuthSlice";
import {
  authTokenKeyName,
  clearLocalStorage,
  refreshTokenKeyName,
} from "src/utils/configs/authConfig";
import { BASE_URL } from "../utils/constants/index";

const tagTypes = ["project", "ticket", "chats"];
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token =
      (getState() as any)?.auth?.accessToken ||
      localStorage.getItem(authTokenKeyName);
    if (token && endpoint !== "getAccessModules") {
      headers.set("x-access-token", token);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error && result?.error?.status === 401) {
    if (!mutex?.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult: any = await baseQuery(
          {
            url: "/user/app/refresh",
            method: "POST",
            body: { refreshToken: localStorage.getItem(refreshTokenKeyName) },
          },
          api,
          extraOptions
        );
        if (refreshResult?.data) {
          localStorage.setItem(
            authTokenKeyName,
            refreshResult?.data?.data?.token
          );
          localStorage.setItem(
            refreshTokenKeyName,
            refreshResult?.data?.data?.refreshToken
          );
          api.dispatch(setAccessToken(refreshResult?.data?.data?.token));
          api.dispatch(
            setRefreshToken(refreshResult?.data?.data?.refreshToken)
          );
          localStorage.setItem(
            "canApproveETA",
            refreshResult?.data?.data?.canApproveETA
          );
          localStorage.setItem(
            "canCloseTicket",
            refreshResult?.data?.data?.canCloseTicket
          );
          localStorage.setItem(
            "userData",
            JSON.stringify({
              name: refreshResult?.data?.data?.name,
              mobile: refreshResult?.data?.data?.mobile,
              email: refreshResult?.data?.data?.email,
              userId: refreshResult?.data?.data?.userId,
              clientId: refreshResult?.data?.data?.clientId,
            })
          );
          result = await baseQuery(args, api, extraOptions);
        } else {
          clearLocalStorage();
          window.location.replace("/login");
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  tagTypes: tagTypes,
  endpoints: () => ({}),
});

export default apiSlice;
