import apiSlice from "./ApiSlice";

type ChatSendPayloadType = {
  ticketId: string;
  message: string;
  messageParentId: string | null;
  messageParentText: string;
  parentMessageUserLabel: string;
};

type MessagePinType = {
  ticketId: string;
  messageId: string;
  message: string;
  // pinById: string;
};
export const chatApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get the all chat via id
    getAllMessages: builder.mutation({
      invalidatesTags: ["chats"],
      query: (ticketId: string) => {
        return {
          url: `/message/app/${ticketId}`,
          method: "GET",
        };
      },
    }),

    // Add Message
    sendMessage: builder.mutation({
      // invalidatesTags: ["chats"],
      query: (body: ChatSendPayloadType) => {
        return {
          url: "/message/app/add",
          method: "POST",
          body,
        };
      },
    }),

    // message pin
    pinMessage: builder.mutation({
      // invalidatesTags: ["chats"],
      query: (body: MessagePinType) => {
        return {
          url: "/message-pin/app/add",
          method: "POST",
          body,
        };
      },
    }),

    // message unpin
    unPinMessage: builder.mutation({
      // invalidatesTags: ["chats"],
      query: (id: string) => {
        return {
          url: `/message-pin/app/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Get Messages Count of Single Ticket
    getMessageCount: builder.query({
      // providesTags: ["chats"],
      query: ({ ticketId, userId }: { ticketId: string; userId: string }) => {
        return {
          url: `/message/app/chat-count/${ticketId}/user/${userId}`,
          method: "GET",
        };
      },
    }),

    // Update Unseen Messages
    unSeenMessageUpdate: builder.mutation({
      // invalidatesTags: ["chats"],
      query: ({ ticketId, userId }: { ticketId: string; userId: string }) => {
        return {
          url: `/message/app/is-seen/${ticketId}/user/${userId}`,
          method: "PUT",
        };
      },
    }),

    // Delete Messages
    deleteMessage: builder.mutation({
      // invalidatesTags: ["chats"],
      query: (messageId: string) => {
        return {
          url: `/message/app/${messageId}`,
          method: "DELETE",
        };
      },
    }),

    // Get Total Project messages
    getTotalUnreadMessageProject: builder.query({
      // invalidatesTags: ["chats"],
      query: (messageId: string) => {
        return {
          url: "user/app/unread-messages",
          method: "GET",
        };
      },
    }),

    //
  }),
});

export const {
  // useGetAllMessagesQuery,
  useGetAllMessagesMutation,
  useSendMessageMutation,
  useGetMessageCountQuery,
  useUnSeenMessageUpdateMutation,
  useDeleteMessageMutation,
  usePinMessageMutation,
  useUnPinMessageMutation,
  useGetTotalUnreadMessageProjectQuery,
} = chatApi;
