import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
// import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { TicketFormValues } from "src/models/Ticket.model";
import MOLReactQuillEditor from "src/components/UI/molecules/MolReactQuilEditor/MOLReactQuillEditor";

type Props = {
  formikProps: FormikProps<TicketFormValues>;
  onClose: () => void;
};

const EditTicketProposedSolution = ({ formikProps, onClose }: Props) => {
  const {
    values,
    setFieldValue,
    isSubmitting,
    //  handleBlur
  } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Update Proposed Solution </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <MOLReactQuillEditor
            label=""
            value={values?.proposedSolution}
            height="200px"
            onChange={(e: any) => {
              setFieldValue("proposedSolution", e);
            }}
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default EditTicketProposedSolution;
