import { ClickAwayListener, Tooltip } from "@mui/material";
import { useState } from "react";
import { MdOutlineLogout } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import ChangePasswordFormWrapper from "./ChangePassword/ChangePasswordFormWrapper";

type UserProfileCardPropTypes = {
  onClickAway: () => void;
  userData: {
    name: string;
    mobile: string;
    email: string;
    memberId: string;
  };
};

const UserProfileCard = ({
  onClickAway,
  userData,
}: UserProfileCardPropTypes) => {
  const [isOpenChangePasswordDialog, setIsOpenChangePasswordDialog] =
    useState<boolean>(false);
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className="absolute top-[40px] right-[20px] w-[290px] shadow-lg rounded animate-[fade_0.5s_ease-in-out] z-[1000]  ">
        <div className="flex gap-3 items-center  bg-slate-50 h-[70px] px-4 border-b border-slate-300">
          <div className="w-[43px] h-[43px] flex justify-center items-center bg-primary-main rounded-full text-white ">
            {userData?.name?.[0]?.toUpperCase()}
          </div>

          <div className="flex-1 overflow-auto">
            <div className="text-slate-700"> {userData?.name || ""} </div>
            <Tooltip title={userData?.email || ""} className="bg-white">
              <div className="text-sm text-slate-500 text-ellipsis overflow-hidden">
                {userData?.email || ""}
              </div>
            </Tooltip>
          </div>
        </div>{" "}
        <div className="border-t border-slate-300 px-7 py-3 bg-white">
          <div className="flex gap-3  text-slate-500 items-center hover:text-primary-main cursor-pointer">
            <RiLockPasswordFill className="text-xl" />
            <div
              className=""
              onClick={() => setIsOpenChangePasswordDialog(true)}
            >
              Change Password
            </div>
          </div>
        </div>
        {/* Change Password Dialog */}
        {isOpenChangePasswordDialog ? (
          <ChangePasswordFormWrapper
            onClose={() => setIsOpenChangePasswordDialog(false)}
          />
        ) : null}
        <div className="border-t border-slate-300 px-7 py-3 bg-white">
          <div className="flex gap-3  text-slate-500 items-center hover:text-primary-main cursor-pointer">
            <MdOutlineLogout className="text-xl" />
            <div
              className=""
              onClick={() => {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/login";
                }, 200);
              }}
            >
              Sign out
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default UserProfileCard;
