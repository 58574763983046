import React, { Suspense } from "react";
const ATMMarkDownEditor = React.lazy(
  () => import("../../atoms/ATMMarkDownEditor/ATMMarkDownEditor")
);

type Props = {
  onChange: (html: any) => void;
  value: any;
  label?: string;
  required?: boolean;
  placeholder?: string;
};

const MOLMarkdownEditor = ({
  value = "",
  onChange,
  label = "",
  required = false,
  placeholder,
}: Props) => {
  return (
    <Suspense fallback={<>loading.....</>}>
      <ATMMarkDownEditor
        label={label}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
      />
    </Suspense>
  );
};

export default MOLMarkdownEditor;
