import { FormikProps } from "formik";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import ClipLoader from "react-spinners/ClipLoader";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import { AddResourceRequestFormValues } from "src/models/CloseResourceRequest.model";
import { getDataWithRightFormat } from "src/screens/Tickets/View/TicketTabs/TicketDetails/TicketDetails";
import useFileUploader from "src/utils/useFileUploader";

type Props = {
  formikProps: FormikProps<AddResourceRequestFormValues>;
  onClose: () => void;
  resourceData: any;
};

const AddResourceRequesrForm = ({
  formikProps,
  onClose,
  resourceData,
}: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  const { initiateUpload, uploadedFile } = useFileUploader({
    next: (file) =>
      setFieldValue("attachments", [
        ...values?.attachments,
        {
          url: file?.fileUrl,
          type: file?.fileType,
          name: file?.fileTitle,
        },
      ]),
    accept: ".jpg, .jpeg, .png,  .mp4, .xls, .xlsx, .pdf, .webp, .webm",
  });

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> Add Resource </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex p-4 bg-slate-50 rounded gap-16">
          <div className="flex flex-col">
            <span className="text-xs">Project</span>
            <span className="font-medium text-slate-800 uppercase">
              {resourceData?.projectName}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-xs">Resource Name</span>
            <span className="font-medium text-slate-800">
              {resourceData?.resourceName}
            </span>
          </div>
        </div>
        {resourceData?.description ? (
          <div className="flex flex-col w-full bg-slate-50 rounded p-4">
            <span className="text-xs">Description</span>
            <span className="font-medium text-slate-800">
              {" "}
              {getDataWithRightFormat(resourceData?.description)}
            </span>
          </div>
        ) : null}

        {/* Remark */}
        <div className="">
          <ATMTextArea
            name="remark"
            value={values.remark}
            onChange={(value) => setFieldValue("remark", value)}
            label="Remark"
            minRows={8}
            placeholder="Enter Remark"
          />
        </div>

        {values?.attachments?.length ? (
          <div className="grid grid-cols-12 gap-4 pb-10">
            <div className="col-span-12 text-slate-700 font-medium text-sm">
              Attachments
            </div>
            {values?.attachments?.map((item: any, index: any) => {
              return (
                <div className="col-span-3 h-[200px]" key={index}>
                  <ATMRenderFile
                    fileInfo={item}
                    isShowCancel={true}
                    onCancelClick={() => {
                      const attachmentInfo = [...values?.attachments];
                      attachmentInfo?.splice(index, 1);
                      setFieldValue("attachments", attachmentInfo);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="flex justify-end mt-3">
          <button
            className="text-primary-main flex gap-1 font-bold items-center"
            type="button"
            onClick={initiateUpload}
            disabled={uploadedFile ? true : false}
          >
            {uploadedFile ? (
              <ClipLoader
                color={"teal"}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <HiOutlinePlusSmall size={20} />
            )}
            Add Attachment
          </button>
        </div>
      </div>

      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default AddResourceRequesrForm;
