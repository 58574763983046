import Chip from "@mui/material/Chip";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { MeetingListResponse } from "src/models/Meeting.model";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setTotalItems,
} from "src/redux/slices/MeetingSlice";
import { RootState } from "src/redux/store";
import { useGetAllMeetingsQuery } from "src/services/MeetingService";
import MeetingListing from "./MeetingListing";
import MOLMeetingStatusChip from "src/components/UI/molecules/MOLMeetingStatusChip/MOLMeetingStatusChip";
import { Tooltip } from "@mui/material";

type Props = {};

const MeetingListingWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const { items, totalItems, page, rowsPerPage, searchValue, isTableLoading } =
    useSelector((state: RootState) => state.meeting);
  const { data, isLoading, isFetching } = useGetAllMeetingsQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: [
      "title",
      "mode",
      "scheduledStartTime",
      "scheduledEndTime",
      "clientUsers",
      "codioticUsers",
      "createdById",
      "createdByUserType",
    ],
    page: page,
    filterBy: [],
    dateFilter: {},
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setItems(data?.data || []));
      dispatch(setIsTableLoading(false));
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, isFetching]);

  const columns: columnTypes[] = [
    {
      field: "createdAt",
      headerName: "Scheduled Date Time",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: MeetingListResponse) => (
        <div className="py-1">
          <div className="text-sm text-slate-700 font-medium">
            {moment(row?.createdAt).format("DD MMM YYYY")}
          </div>
          <div className="flex mt-1 items-center">
            <span className="text-xs text-slate-500 font-medium">
              {moment(row?.scheduledStartTime).format("hh:mm A")}
            </span>
            {row?.scheduledEndTime && (
              <span className="flex">
                <span className="text-xs text-slate-500 font-bold mx-1">:</span>
                <span className="text-xs text-slate-500 font-medium">
                  {moment(row?.scheduledEndTime).format("hh:mm A")}
                </span>
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      field: "actualStartTime",
      headerName: "Actual Date Time",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "min-w-[180px]",
      renderCell: (row: MeetingListResponse) =>
        row?.actualStartTime ? (
          <div className="py-1">
            <div className="text-sm text-slate-700 font-medium">
              {moment(row?.actualStartTime).format("DD MMM YYYY")}
            </div>
            <div className="flex mt-1">
              <span className="text-xs text-slate-500 font-medium">
                {moment(row?.actualStartTime).format("hh:mm A")}
              </span>
              {row?.actualEndTime && (
                <div className="flex items-center">
                  <span className="text-xs text-slate-500 font-bold mx-1">
                    :
                  </span>
                  <span className="text-xs text-slate-500 font-medium">
                    {moment(row?.actualEndTime).format("hh:mm A")}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          " - "
        ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: "flex-[1.5_1.5_0%]",
      extraClasses: "",
      renderCell: (row: MeetingListResponse) => (
        <Tooltip title={row?.title}>
          <div className="capitalize line-clamp-2 w-full"> {row?.title} </div>
        </Tooltip>
      ),
    },
    {
      field: "projectName",
      headerName: "Project",
      flex: "flex-[1_1_0%]",
      renderCell: (row: MeetingListResponse) => (
        <div className="capitalize"> {row?.projectName} </div>
      ),
    },
    {
      field: "mode",
      headerName: "Mode",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: MeetingListResponse) => (
        <div>
          <div className="capitalize">{row?.mode}</div>
          <div>
            {row?.mode === "ONLINE" && (
              <a
                className="text-blue-400 cursor-pointer"
                target="_blank"
                rel="noreferrer"
                href={`${row?.meetingLink}`}
              >
                Link
              </a>
            )}
            {row?.mode === "OFFLINE" && (
              <Chip label={`${row?.address}`} className="me-2 my-2" />
            )}
          </div>
        </div>
      ),
    },
    {
      field: "requiredThings",
      headerName: "Required Things",
      flex: "flex-[1_1_0%]",
      extraClasses: "",
      renderCell: (row: MeetingListResponse) => (
        <div className="capitalize"> {row?.requiredThings} </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1_1_0%]",
      renderCell: (row: MeetingListResponse) => (
        <div className="capitalize">
          {" "}
          {<MOLMeetingStatusChip status={row?.status} />}{" "}
        </div>
      ),
    },
  ];

  return (
    <div>
      <MeetingListing
        columns={columns}
        rows={items}
        paginationProps={{
          isTableLoading,
          totalItems,
          page,
          rowsPerPage,
          searchValue,
          setPage: (newPage) => dispatch(setPage(newPage)),
          setRowsPerPage: (newRowsPerPage) =>
            dispatch(setRowsPerPage(newRowsPerPage)),
          setSearchValue: (newValue) => dispatch(setSearchValue(newValue)),
        }}
      />
    </div>
  );
};

export default MeetingListingWrapper;
