import { useEffect } from "react";
import { HiChatAlt2, HiViewGrid } from "react-icons/hi";
import { TbListDetails } from "react-icons/tb";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ATMTab, { Tabs } from "src/components/UI/atoms/ATMTab/ATMTab";
import MOLTicketStatusChip from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import MOLTicketTypeChip from "src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { getFormattedEstimationTime } from "src/helpers/getFormattedEstimationTime";
import { setTotalUnSeenMessageCount } from "src/redux/slices/ChatSlice";
import { useGetMessageCountQuery } from "src/services/ChatServices";

// Redux
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setSingleItem } from "src/redux/slices/TicketSlice";
import { RootState } from "src/redux/store";
import {
  useApproveTicketETAMutation,
  useGetSingleTicketsViaIdQuery,
} from "src/services/TicketService";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import { IoMdArrowRoundBack } from "react-icons/io";
import { CircularProgress } from "@mui/material";

type Props = {};

const InfoField = ({ label, value }: { label: string; value: any }) => {
  return (
    <div className="px-4 py-2 border-b">
      <div className="text-gray-500 pb-1"> {label} </div>
      <div className="text-slate-600 font-medium"> {value} </div>
    </div>
  );
};

enum TicketTypeEnum {
  bug = "BUG",
  support = "SUPPORT",
  change_request = "CHANGE_REQUEST",
}

enum TicketStatusEnum {
  unMapped = "UNMAPPED",
  mapped = "MAPPED",
  approval_pending = "APPROVAL_PENDING",
  approval_done = "APPROVAL_DONE",
  done = "DONE",
}

const TicketViewLayout = (props: Props) => {
  // Local State
  const { unSeenMessageCount } = useSelector((state: RootState) => state.chat);
  const { singleItem } = useSelector((state: RootState) => state.ticket);
  const [approveETA] = useApproveTicketETAMutation();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get Userinformation from localStorage
  const userData =
    localStorage.getItem("userData") !== "undefined"
      ? JSON.parse(localStorage.getItem("userData") || "{}")
      : null;

  const currentPath = pathname?.split("/")?.[5];
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  const {
    isLoading,
    isFetching,
    data: messageCountData,
  } = useGetMessageCountQuery({
    ticketId: TICKET_ID || "",
    userId: userData?.userId,
  });

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetSingleTicketsViaIdQuery(TICKET_ID || "", {
    skip: !TICKET_ID,
  });
  // Set Single Ticket Details
  useEffect(() => {
    if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
      dispatch(setSingleItem(ticketData?.data || []));
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData, dispatch]);

  const tabs: Tabs[] = [
    {
      label: "Ticket Details",
      icon: TbListDetails,
      path: "details",
      onClick: () => {
        navigate("details");
      },
    },
    {
      label: "Summary",
      icon: HiViewGrid,
      path: "summary",
      onClick: () => {
        navigate("summary");
      },
    },
    {
      label: "Chat",
      icon: HiChatAlt2,
      path: "chat",
      onClick: () => {
        navigate("chat");
      },
    },
    {
      label: "Closed Remark",
      icon: HiViewGrid,
      path: "close-remark",
      onClick: () => {
        navigate("close-remark");
      },
    },
  ];

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setTotalUnSeenMessageCount(messageCountData?.data));
    }
  }, [isFetching, isLoading, messageCountData, dispatch]);

  return (
    <SideNavLayout>
      {isLoading || isFetching ? (
        <div className="absolute w-[80%] h-[100%] flex justify-center items-center z-10 ">
          <CircularProgress />
        </div>
      ) : (
        <div className="h-full flex flex-col">
          {/*Info Header */}
          <div className="flex justify-between items-center p-4 border-b">
            <div className="flex gap-4 items-center">
              <div>
                <ATMLoadingButton
                  onClick={() => navigate(`/project/${params?.projectId}`)}
                >
                  <div className="flex gap-2 items-center">
                    <IoMdArrowRoundBack /> Go to project
                  </div>
                </ATMLoadingButton>
              </div>

              {/* Ticket Type */}
              {singleItem[0]?.status === "UNMAPPED" ? (
                <div>
                  <div className="font-medium px-2 py-[2px]  text-[14px] text-[#196674] ">
                    Unassigned
                  </div>
                </div>
              ) : (
                <MOLTicketTypeChip ticketType={singleItem[0]?.type as any} />
              )}

              {/* Title */}
              <div className="text-[18px] capitalize">
                {singleItem[0]?.title}
              </div>
            </div>

            <div className="flex gap-4 items-center">
              {/* Ticket Status */}
              <div>
                <MOLTicketStatusChip status={singleItem[0]?.status as any} />
              </div>

              {localStorage.getItem("canApproveETA") === "true" &&
                singleItem[0]?.type === TicketTypeEnum.change_request &&
                singleItem[0]?.status === TicketStatusEnum.approval_pending ? (
                <div>
                  <ATMLoadingButton
                    className="bg-primary-dark"
                    onClick={() => {
                      showConfirmationDialog({
                        title: "Are you sure?",
                        text: "Want to approve ETA?",
                        next: (result) => {
                          if (result?.isConfirmed) {
                            approveETA({
                              body: { isEtaApproved: true },
                              ticketId: TICKET_ID,
                            }).then((res: any) => {
                              if (res?.error) {
                                showToast("error", res?.error?.data?.message);
                              } else {
                                if (res?.data?.status) {
                                  showToast("success", res?.data?.message);
                                } else {
                                  showToast("error", res?.data?.message);
                                }
                              }
                            });
                          }
                        },
                      });
                    }}
                  >
                    Approve ETA
                  </ATMLoadingButton>
                </div>
              ) : null}
            </div>
          </div>

          {/* Tabs */}
          <div>
            <ATMTab
              tabs={tabs.filter((tab) => singleItem?.[0]?.clientSideTicketStatus === "CLOSE" ? true : tab.path !== "close-remark")}
              selected={(tab: any) => tab.path === currentPath}
              childrenLabel={currentPath === "chat" ? "" : "Chat"}
              children={
                unSeenMessageCount ? (
                  <div className="bg-[#041618] rounded-full font-bold h-[23px] w-[23px] text-[10px] flex justify-center items-center -right-[6px] -top-[1px] text-white absolute">
                    {unSeenMessageCount}
                  </div>
                ) : null
              }
            />
          </div>

          <div className="flex flex-1 overflow-auto ">
            {/* Main Section */}
            <div className="w-[calc(100%-300px)] h-full">
              <Outlet />
            </div>

            {/* Info Side Bar */}
            <div className="border-l border-slate-300 min-w-[300px] max-w-[300px] h-full overflow-auto">
              <InfoField
                label="Created On"
                value={moment(singleItem[0]?.createdAt).format("DD MMM yyyy")}
              />
              <InfoField
                label="Created By"
                value={singleItem[0]?.createdByLabel}
              />
              {singleItem[0]?.estTime ? (
                <InfoField
                  label="Estimated Time"
                  value={
                    getFormattedEstimationTime(
                      parseInt(singleItem[0]?.estTime)
                    ) || "-"
                  }
                />
              ) : null}
              {singleItem[0]?.timeTaken ? (
                <InfoField
                  label="Time Spent"
                  value={
                    getFormattedEstimationTime(singleItem[0]?.timeTaken) || ""
                  }
                />
              ) : null}
              {singleItem[0]?.numberOfDeveloper ? (
                <InfoField
                  label="No. Of Developers"
                  value={singleItem[0]?.numberOfDeveloper?.toString() || ""}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </SideNavLayout>
  );
};

export default TicketViewLayout;
