import { CircularProgress } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { AddResourceRequestFormValues } from "src/models/CloseResourceRequest.model";
import { FILE_FORMAT } from "src/models/Ticket.model";
import {
  useAddResourceRequestMutation,
  useGetResourceRequestByIdQuery,
} from "src/services/ResourceRequestService";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import AddResourceRequesrForm from "./AddResourceRequestForm";

const AddResourceRequestFormWrapper = () => {
  const navigate = useNavigate();
  const { resourceRequestId } = useParams();
  const [resourceData, setResourceData] = useState<any>();
  const [addResourceRequest] = useAddResourceRequestMutation();
  const { data, isLoading, isFetching } =
    useGetResourceRequestByIdQuery(resourceRequestId);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setResourceData(data?.data);
    }
  }, [data, isLoading, isFetching]);

  const initialValues: AddResourceRequestFormValues = {
    remark: resourceData?.remark || "",
    attachments: resourceData?.attachments || [],
  };

  const validationSchema = object({
    remark: string().required("Please enter remark"),
  });

  const handleSubmit = (
    values: AddResourceRequestFormValues,
    { setSubmitting, resetForm }: FormikHelpers<AddResourceRequestFormValues>
  ) => {
    const formattedValues = {
      ...values,
      attachments: values?.attachments?.map(
        (attachment: {
          url: string;
          type: "images" | "pdfs" | "videos" | "xlsx";
        }) => {
          return {
            url: attachment?.url,
            type: FILE_FORMAT[attachment.type],
          };
        }
      ),
    };
    addResourceRequest({
      id: resourceRequestId,
      body: formattedValues,
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          navigate(`/resource-request`);
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <SideNavLayout>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form className="h-full">
            {isLoading || isFetching ? (
              <div className="absolute w-[80%] h-[100%] flex justify-center items-center z-10 ">
                <CircularProgress />
              </div>
            ) : (
              <div className="flex justify-center ">
                <div className="w-[90%]">
                  <AddResourceRequesrForm
                    formikProps={formikProps}
                    onClose={() => navigate(`/resource-request`)}
                    resourceData={resourceData}
                  />
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </SideNavLayout>
  );
};

export default AddResourceRequestFormWrapper;
