import { useEffect, useState } from "react";
import { RxDividerVertical } from "react-icons/rx";
import { useParams } from "react-router-dom";
import { useGetAllTicketsCountQuery } from "src/services/TicketService";
import ProjectTicketListingWrapper from "../ProjectTickets/ProjectTicketListingWrapper";
import moment from "moment";

type LabelType = "UN_ASSIGNED" | "CHANGE_REQUEST" | "SUPPORT" | "BUG";

const labelWiseFormat = {
  UN_ASSIGNED: {
    labelName: "Unassigned",
    extraClasses: "text-yellow-500  bg-yellow-200",
  },
  CHANGE_REQUEST: {
    labelName: "New Change",
    extraClasses: "text-green-600 bg-green-200",
  },
  SUPPORT: {
    labelName: "Support",
    extraClasses: "text-sky-500  bg-sky-200",
  },
  BUG: {
    labelName: "Bug",
    extraClasses: "text-slate-500  bg-slate-200",
  },
};

const TicketCountCard = ({
  label,
  count,
  extraClasses,
  getTicketsTime,
}: {
  label: LabelType;
  count: number;
  extraClasses: string;
  getTicketsTime: (label: string) => number | undefined;
}) => {
  const timeSpent = getTicketsTime(label);

  return (
    <div className={`flex-1 flex gap-4 p-4 rounded-md ${extraClasses}`}>
      <div
        className={`w-fit aspect-square p-2 px-4 text-xl font-bold flex items-center justify-center rounded-lg ${labelWiseFormat[label]?.extraClasses}`}
      >
        {count}
      </div>
      <div className="">
        <span className={`font-semibold text-sm`}>
          {labelWiseFormat[label].labelName}
        </span>
        <div className="flex gap-2 items-end text-base text-gray-900">
          <span className="font-medium">Time spent:</span>
          <span className="font-medium">{timeSpent}</span>
        </div>
      </div>
    </div>
  );
};

const ProjectSingleView = () => {
  const [ticketsCount, setTicketsCount] = useState<any>([]);
  const [totalTickets, setTotalTickets] = useState<any>();
  const [ticketsTime, setTicketsTime] = useState<any>();
  const { projectId } = useParams();

  const {
    data: ticketCountData,
    isLoading: isLoadingTicketCountData,
    isFetching: isFetchingTicketCountData,
  } = useGetAllTicketsCountQuery(
    {
      projectId: projectId,
      dateFilter: {
        startDate: "",
        endDate: "",
      },
    },
    { skip: !projectId }
  );

  useEffect(() => {
    if (!isLoadingTicketCountData || !isFetchingTicketCountData) {
      setTicketsCount(ticketCountData?.data);
    }
  }, [isLoadingTicketCountData, isFetchingTicketCountData, ticketCountData]);

  useEffect(() => {
    let allTicketsCount = ticketsCount?.reduce(
      (accumulator: any, currentValue: any) => {
        // Ensure that currentValue.Count is a number before adding it to the accumulator
        let countValue = currentValue?.count;
        return accumulator + countValue;
      },
      0
    );
    let ticketTime = ticketsCount?.reduce(
      (accumulator: any, currentValue: any) => {
        // Ensure that currentValue.Count is a number before adding it to the accumulator
        const [hours, minutes] = currentValue?.totalHours?.split(":");
        return accumulator.add(
          moment.duration({
            hours: parseInt(hours, 10),
            minutes: parseInt(minutes, 10),
          })
        );
      },
      moment.duration()
    );
    const formattedTotalTicketTime = moment
      .utc((ticketTime)?.as("milliseconds") || 0)
      .format("HH:mm");
    setTicketsTime(formattedTotalTicketTime);
    setTotalTickets(allTicketsCount);
  }, [ticketsCount]);

  //Get Tickets Count
  const getTicketsCount = (label: string) => {
    const ticketCount = ticketsCount?.find(
      (el: any) => el?.type === label
    )?.count;
    return ticketCount;
  };

  //Get Tickets Count
  const getTicketsTime = (label: string) => {
    const ticketCount = ticketsCount?.find(
      (el: any) => el?.type === label
    )?.totalHours;
    return ticketCount;
  };

  return (
    <div className="flex flex-col gap-4 flex-1 overflow-hidden">
      {/* Summary */}
      <div className="flex gap-4 items-center ">
        {/* Tickets */}
        <div className="hidden">
          <div className="p-4 border rounded flex flex-col gap-4 flex-1">
            <div className="flex gap-4 items-center">
              <div className="text-slate-600 font-semibold text-sm rounded-2xl">
                Tickets ({totalTickets || 0})
              </div>
              <RxDividerVertical />
              <div className="text-slate-600 font-semibold text-sm rounded-2xl">
                Total Time Spent ({ticketsTime || 0})
              </div>
            </div>

            <div className="flex gap-4">
              <TicketCountCard
                extraClasses="bg-green-50"
                label={"CHANGE_REQUEST"}
                count={getTicketsCount("CHANGE_REQUEST") || 0}
                getTicketsTime={getTicketsTime}
              />
              {/* <TicketCountCard
              extraClasses="bg-yellow-50"
              label={"UN_ASSIGNED"}
              count={getTicketsCount("UN_ASSIGNED") || 0}
              getTicketsTime={getTicketsTime}
            /> */}
              <TicketCountCard
                extraClasses="bg-sky-50"
                label={"SUPPORT"}
                count={getTicketsCount("SUPPORT") || 0}
                getTicketsTime={getTicketsTime}
              />
              <TicketCountCard
                extraClasses="bg-slate-50"
                label={"BUG"}
                count={getTicketsCount("BUG") || 0}
                getTicketsTime={getTicketsTime}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Tickets */}
      <div className="flex-1 overflow-auto">
        <ProjectTicketListingWrapper />
      </div>
    </div>
  );
};

export default ProjectSingleView;
