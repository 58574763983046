import apiSlice from "./ApiSlice";

export const MeetingServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET LIST
    getAllMeetings: builder.query({
      providesTags:["meeting"],
      query: (body) => {
        return {
          url: "/meeting/app",
          method: "POST",
          body,
        };
      },
    }),
    // GET By ID
    getMeetingById: builder.query({
      providesTags:["meeting"],
      query: (id) => {
        return {
          url: `/meeting/app/${id}`,
          method: "GET",
        };
      },
    }),
    // GET MEETING NOTES
    getMeetingNotes: builder.query({
      query: (id) => {
        return {
          url: `/meeting/notes/${id}/app`,
          method: "GET",
        };
      },
    }),
    // APPROVE MOM
    approveMom: builder.mutation({
      invalidatesTags:["meeting"],
      query: (id) => {
        return {
          url: `/meeting/approve-mom/${id}/app`,
          method: 'PUT',
        };
      },
    }),       
  }),
});

export const {
  useGetAllMeetingsQuery,
  useGetMeetingByIdQuery,
  useGetMeetingNotesQuery,
  useApproveMomMutation
} = MeetingServiceApi;
