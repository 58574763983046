import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import apiSlice from "src/services/ApiSlice";
import { fileExplorerSlice } from "src/services/FileExplorer";
import ActivitySlice from "./slices/ActivitySlice";
import ActivityDateSlice from "./slices/ActivityDateSlice";
import { sideNavLayoutSlice } from "./slices";
import AuthSlice from "./slices/AuthSlice";
import ChangeRequestSlice from "./slices/ChangeRequestSlice";
import ChatSlice from "./slices/ChatSlice";
import ProjectSlice from "./slices/ProjectSlice";
import ResourceRequestSlice from "./slices/ResourceRequestSlice";
import TicketSlice from "./slices/TicketSlice";
import { filePickerSlice } from "src/services/FilePickerServices";
import MeetingSlice from "./slices/MeetingSlice";

const store = configureStore({
  reducer: {
    sideNavLayout: sideNavLayoutSlice,
    auth: AuthSlice,
    project: ProjectSlice,
    ticket: TicketSlice,
    chat: ChatSlice,
    changeRequest: ChangeRequestSlice,
    activity: ActivitySlice,
    activityDate: ActivityDateSlice ,
    resourceRequest: ResourceRequestSlice,
    meeting: MeetingSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [filePickerSlice.reducerPath]: filePickerSlice.reducer,
    [fileExplorerSlice.reducerPath]: fileExplorerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      apiSlice.middleware,
      fileExplorerSlice.middleware,
      filePickerSlice.middleware,
    ]),
});

setupListeners(store.dispatch);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
