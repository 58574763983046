import { TYPPriority } from "src/components/UI/molecules/MOLPriorityChip/MOLPriorityChip";
import { TYPTicketStatus } from "src/components/UI/molecules/MOLTicketStatusChip/MOLTicketStatusChip";
import { TYPTicketType } from "src/components/UI/molecules/MOLTicketTypeChip/MOLTicketTypeChip";

export const ticketStatus: { label: any; value: TYPTicketStatus }[] = [
  {
    label: "Mapped",
    value: "MAPPED",
  },
  {
    label: "Approval Pending",
    value: "APPROVAL_PENDING",
  },
  {
    label: "Approval Done",
    value: "APPROVAL_DONE",
  },
  {
    label: "Assigned",
    value: "ASSIGNED",
  },
  {
    label: "Done",
    value: "DONE",
  },
  {
    label: "Closed",
    value: "CLOSED",
  },
];

export const ticketPriorites: { label: any; value: TYPPriority }[] = [
  {
    label: "Low",
    value: "LOW",
  },
  {
    label: "Medium",
    value: "MEDIUM",
  },
  {
    label: "High",
    value: "HIGH",
  },
  {
    label: "Urgent",
    value: "URGENT",
  },
];

export const ticketTypes: { label: any; value: TYPTicketType }[] = [
  {
    label: "Support",
    value: "SUPPORT",
  },
  {
    label: "Change Request",
    value: "CHANGE_REQUEST",
  },
  {
    label: "Bug",
    value: "BUG",
  },
  {
    label: "Other",
    value: "OTHER",
  },
  {
    label: "Missing",
    value: "MISSING_FEATURE",
  },
  {
    label: "Task",
    value: "TASK",
  },
];

export const getEstimationInDays = ({
  time,
  workingHours,
}: {
  time: number;
  workingHours: number;
}) => {
  let workingHoursInMinutes = workingHours * 60;
  if (time === 0) {
    return "-";
  }
  const days = Math.ceil(time / (workingHoursInMinutes || 390));

  const daysString = days ? `${days} ${days > 1 ? "days" : "day"} ` : "";
  return `${daysString}`;
};

export const getTimeInHours = (timeInMinutes: number) => {
  if (timeInMinutes === 0) {
    return "0m";
  }
  let hours = Math.floor(Number(timeInMinutes) / 60);
  let remainingMinutes = Number(timeInMinutes) % 60;
  return hours > 0
    ? `${hours}h ${remainingMinutes ? remainingMinutes + "m" : ""}`
    : `${timeInMinutes}m`;
};
