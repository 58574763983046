import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";

type Props = {
  formikProps: FormikProps<any>;
  onClose: () => void;
};

const ApproveETARequest = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">Decline ETA</div>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <ATMTextArea
            placeholder="Remark"
            name="approvalRemark"
            value={values?.approvalRemark}
            onChange={(newValue) => {
              setFieldValue("approvalRemark", newValue);
            }}
            label="Remark"
            minRows={4}
          />
        </div>
      </div>
      <div className="flex gap-4 justify-end">
        <div>
          <ATMLoadingButton type="button" onClick={onClose}>
            No, Cancel
          </ATMLoadingButton>
        </div>

        <div>
          <ATMLoadingButton
            className="bg-red-400"
            isLoading={isSubmitting}
            type="submit"
          >
            Yes, Decline
          </ATMLoadingButton>
        </div>
      </div>
    </div>
  );
};

export default ApproveETARequest;
