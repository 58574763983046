import { Dialog } from "@mui/material";
import { useState } from "react";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { MdCancel, MdOutlineFileDownload } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

type Props = {
  fileInfo: {
    type: string;
    url: string;
    name: string;
    renderType?: "ADD" | "VIEW";
  };
  isShowCancel?: boolean;
  onCancelClick?: () => void;
};
const ImageFile = ({ fileInfo }: Props) => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  return (
    <>
      <img
        src={fileInfo.url}
        alt=""
        className="w-full h-full rounded cursor-pointer"
        onClick={() => setIsShowDialog(true)}
      />
      <Dialog open={isShowDialog} fullScreen>
        <div className="flex gap-5 flex-col justify-center items-center p-4 bg-gray-200 border-2   ">
          <MdCancel
            color="black"
            size={25}
            className="sticky top-3 self-end cursor-pointer"
            onClick={() => setIsShowDialog(false)}
          />
          <img src={fileInfo.url} alt="" />
        </div>
      </Dialog>
    </>
  );
};

const VideoFile = ({ fileInfo }: Props) => {
  const fileType = fileInfo?.url?.split(".")?.at(-1) || "*";
  return fileInfo?.url ? (
    <video controls width="100%" style={{ height: "100%" }}>
      <source src={fileInfo?.url} type={`video/${fileType}`} />
    </video>
  ) : null;
};
const PdfFile = ({ fileInfo }: Props) => {
  const fileName = fileInfo?.name || fileInfo?.url?.split("/")?.at(-1) || "";
  return (
    <a href={fileInfo?.url} target="_blank" rel="noreferrer">
      <div className="flex flex-col gap-2 flex-wrap justify-center items-center">
        <FaFilePdf color="red" size={25} />
        <span className="font-medium text-center text-xs  break-all w-full">
          {fileName}
        </span>
      </div>
    </a>
  );
};
const ExcelFile = ({ fileInfo }: Props) => {
  const fileName = fileInfo?.name || fileInfo?.url?.split("/")?.at(-1) || "";
  return (
    <div className="flex flex-col gap-2 items-center justify-center flex-wrap p-2 rounded ">
      <FaFileExcel size={25} color="green" />
      <span className="font-medium text-center text-xs break-all w-full">
        {fileName}
      </span>
      {fileInfo?.renderType === "VIEW" && (
        <a href={fileInfo?.url} download>
          <MdOutlineFileDownload
            size={25}
            className="cursor-pointer hover:text-cyan-700"
          />
        </a>
      )}
    </div>
  );
};

const ATMRenderFile = ({
  fileInfo,
  isShowCancel = false,
  onCancelClick,
}: Props) => {
  const fileData = {
    ...fileInfo,
    type:
      fileInfo?.type === "DOCUMENT"
        ? fileInfo?.url?.split(".")?.at(-1)?.toUpperCase() || ""
        : fileInfo?.type || "",
    url: fileInfo?.url,
  };
  const getComponent = () => {
    if (fileData?.type === "images" || fileData?.type === "IMAGE") {
      return <ImageFile fileInfo={fileData} />;
    } else if (fileData?.type === "videos" || fileData?.type === "VIDEO") {
      return <VideoFile fileInfo={fileData} />;
    } else if (fileData?.type === "pdfs" || fileData?.type === "PDF") {
      return <PdfFile fileInfo={fileData} />;
    } else if (fileData?.type === "xlsx" || fileData?.type === "XLSX") {
      return <ExcelFile fileInfo={fileData} />;
    } else {
      return <ImageFile fileInfo={fileData} />;
    }
  };

  return (
    <div
      className={`border-2 rounded border-slate-400 border-dashed px-3 pb-3  ${
        isShowCancel ? "pt-8" : "pt-3"
      } w-full h-full mt-3 flex items-center justify-center relative`}
    >
      {isShowCancel ? (
        <div
          className="absolute top-[5px] right-[5px] bg-slate-500 rounded-xl cursor-pointer hover:bg-red-500 p-1"
          onClick={onCancelClick}
        >
          <RxCross2 size={15} color="white" />
        </div>
      ) : null}
      {getComponent()}
    </div>
  );
};

export default ATMRenderFile;
