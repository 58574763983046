import apiSlice from "./ApiSlice";

export const ticketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //add
    addTicket: builder.mutation({
      invalidatesTags: ["ticket"],
      query: (body) => ({
        url: "/ticket/app/add",
        method: "POST",
        body,
      }),
    }),

    //Get By Id
    getTicketById: builder.query({
      providesTags: ["ticket"],
      query: (id: any) => {
        return {
          url: `/ticket/app/${id}`,
          method: "GET",
        };
      },
    }),

    // GET
    getAllTicketWithoutPagination: builder.query({
      providesTags: ["ticket"],
      query: (body) => {
        return {
          url: "/ticket/app",
          method: "POST",
          body,
        };
      },
    }),

    // GET BY ID
    getSingleTicketsViaId: builder.query({
      providesTags: ["ticket"],
      query: (ticketId) => {
        return {
          url: `/ticket/app/${ticketId}`,
          method: "GET",
        };
      },
    }),
    // GET ALL Ticket Count
    getAllTicketsCount: builder.query({
      query: (body) => {
        return {
          url: "dashboard/app/get-project-info",
          method: "POST",
          body,
        };
      },
    }),

    // APPROVE ETA
    approveTicketETA: builder.mutation({
      invalidatesTags: ["ticket"],
      query: ({ body, ticketId }) => {
        return {
          url: `/ticket/app/${ticketId}/eta-approve`,
          method: "PUT",
          body,
        };
      },
    }),

    //Edit Ticket
    editTicket: builder.mutation({
      invalidatesTags: ["ticket"],
      query: ({ body, ticketId }) => {
        return {
          url: `/ticket/app/${ticketId}`,
          method: "PUT",
          body,
        };
      },
    }),
    // Get All the client user
    getAllUsers: builder.query({
      providesTags: ["ticket"],
      query: (id: any) => {
        return {
          url: `/client/users/${id}/app`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllTicketWithoutPaginationQuery,
  useGetSingleTicketsViaIdQuery,
  useApproveTicketETAMutation,
  useAddTicketMutation,
  useGetAllTicketsCountQuery,
  useEditTicketMutation,
  useGetAllUsersQuery
} = ticketApi;
