import { useEffect, ReactNode } from "react";
// Modals
import EditTicketProblemStatement from "src/screens/Tickets/Edit/EditTicketProblemStatement";
import EditTicketProposedSolution from "src/screens/Tickets/Edit/EditTicketProposedSolution";
import EditTicketTechnicalApproach from "src/screens/Tickets/Edit/EditTicketTechnicalApproach";
import EditTicketAffectedAreas from "src/screens/Tickets/Edit/EditTicketAffectedAreas";
// Redux
import { useGetSingleTicketsViaIdQuery } from "src/services/TicketService";
import { useParams } from "react-router-dom";
import { RootState } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSingleItem } from "src/redux/slices/TicketSlice";
import MOLBackendHtmlRenderer from "src/components/UI/molecules/MOLBackendHtmlRenderer/MOLBackendHtmlRenderer";
// import ReactMarkdown from "react-markdown";

type Props = {};

const CustomSection = ({
  heading,
  value,
  formComponent,
}: {
  heading: string;
  value: string | ReactNode;
  formComponent?: any;
}) => {


  return (
    <div className="flex flex-col gap-2">
      <div className="flex">
        <div className="text-xl font-medium"> {heading} </div>
        
      </div>
      <div className="flex overflow-auto max-h-40">
        {" "}
        {/* Adjust max-h-40 to your desired max height */}
        {value}
      </div>

    </div>
  );
};

const TicketSummary = (props: Props) => {
  // Get the Ticket id
  const params = useParams();
  const TICKET_ID = params?.ticketId;

  // redux state
  const { singleItem } = useSelector((state: RootState) => state.ticket);
  // dispatch
  const dispatch = useDispatch();

  // Get Single Ticket Details
  const {
    isLoading: isTicketDetailsLoading,
    isFetching: isTicketDetailsFetching,
    data: ticketData,
  } = useGetSingleTicketsViaIdQuery(TICKET_ID || "", {
    skip: !TICKET_ID,
  });

  // Set Single Ticket Details
  useEffect(() => {
    if (!isTicketDetailsLoading && !isTicketDetailsFetching) {
      dispatch(setSingleItem(ticketData?.data || []));
    }
  }, [isTicketDetailsLoading, isTicketDetailsFetching, ticketData, dispatch]);

  return (
    <div className="p-4 flex flex-col gap-6 overflow-auto">
      {/* Problem Statement */}
      <CustomSection
        heading="Problem Statement"
        value={
          <MOLBackendHtmlRenderer
            htmlContent={singleItem[0]?.problemStatement || "-"}
          />
        }
        formComponent={EditTicketProblemStatement}
      />

      {/* Proposed Solution */}
      <CustomSection
        heading="Proposed Solution"
        value={
          <MOLBackendHtmlRenderer
            htmlContent={singleItem[0]?.problemSolution || "-"}
          />
        }
        formComponent={EditTicketProposedSolution}
      />


      {/* Technical Approach */}
      <CustomSection
        heading="Technical Approach"
        value={
          <MOLBackendHtmlRenderer
            htmlContent={singleItem[0]?.technicalApproach || "-"}
          />
        }
        formComponent={EditTicketTechnicalApproach}
      />
      <CustomSection
        heading="Affected Areas"
        value={
          singleItem[0]?.affectedAreas?.length ? (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Project</th>
                    <th>Feature</th>
                  </tr>
                </thead>
                <tbody>
                  {singleItem[0]?.affectedAreas?.map((ele : any) => {
                    return (
                      <tr className="border-b border-gray-300 hover:bg-gray-100">
                        <td className="py-2 px-4">{ele?.projectLabel}</td>
                        <td className="py-2 px-4">{ele?.featureLabel}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            "-"
          )
        }
        formComponent={EditTicketAffectedAreas}
      />
    </div>
  );
};

export default TicketSummary;
