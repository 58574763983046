import { CircularProgress } from "@mui/material";
import { FormikProps } from "formik";
import { lazy, Suspense } from "react";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import ClipLoader from "react-spinners/ClipLoader";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import ATMSelect from "src/components/UI/atoms/formFields/ATMSelect/ATMSelect";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { useGetAllProjects } from "src/hooks/useGetAllProject";
import { AddTicketFormValues } from "src/models/Ticket.model";
import useFileUploader from "src/utils/useFileUploader";

const ATMHTMLEditor = lazy(
  () => import("src/components/UI/atoms/formFields/ATMHTMLEditor/ATMHTMLEditor")
);

type Props = {
  formikProps: FormikProps<AddTicketFormValues>;
  onClose: () => void;
  formType: "Add" | "Edit";
};
export const TICKET_PRIORITY = [
  {
    label: "Low",
    value: "LOW",
  },
  {
    label: "Medium",
    value: "MEDIUM",
  },
  {
    label: "High",
    value: "HIGH",
  },
  {
    label: "Urgent",
    value: "URGENT",
  },
];

const TicketForm = ({ formikProps, onClose, formType }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  // get all projects hook
  const { allProjects, isDataLoading: isAllProjectsDataLoading } =
    useGetAllProjects();
  const { initiateUpload, uploadedFile } = useFileUploader({
    next: (file) =>
      setFieldValue("attachments", [
        ...values?.attachments,
        {
          url: file?.fileUrl,
          type: file?.fileType,
          name: file?.fileTitle,
        },
      ]),
    accept: ".jpg, .jpeg, .png,  .mp4, .xls, .xlsx, .pdf, .webp, .webm",
  });
  //All Project Options
  const ProjectsOption = allProjects?.map((el: any) => {
    return {
      label: el?.projectName,
      value: el?._id,
    };
  });

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium"> {formType} Ticket </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Project */}
        <div className="">
          <ATMSelect
            required
            disabled
            name="projectId"
            value={ProjectsOption?.find(
              (project: any) => project?.value === values?.projectId
            )}
            label="Project"
            placeholder="Select Project"
            options={ProjectsOption}
            isLoading={isAllProjectsDataLoading}
            onBlur={handleBlur}
            onChange={(newValue) => {
              setFieldValue("projectId", newValue?.value);
            }}
          />
        </div>

        {/* Module Name */}
        <div className="">
          <ATMSelect
            required
            name="priority"
            value={values.priority}
            label="Priority"
            placeholder="Select ticket priority"
            options={TICKET_PRIORITY}
            onBlur={handleBlur}
            onChange={(newValue) => {
              setFieldValue("priority", newValue);
            }}
            menuPosition="absolute"
          />
        </div>

        {/* Title */}
        <div className="">
          <ATMTextField
            name="title"
            value={values.title}
            onChange={(e) => setFieldValue("title", e.target.value)}
            label="Title"
            placeholder="Enter title"
            onBlur={handleBlur}
          />
        </div>

        {/* Description */}
        <Suspense
          fallback={
            <div className="flex justify-center items-center">
              <CircularProgress />
            </div>
          }
        >
          <div>
            <ATMHTMLEditor
              label="Description"
              required
              value={values?.description}
              placeholder="Write description here...."
              onChange={(newValue: any) => {
                setFieldValue("description", newValue);
              }}
            />
          </div>
        </Suspense>

        {values?.attachments?.length ? (
          <div className="grid grid-cols-12 gap-4 pb-10">
            <div className="col-span-12 text-slate-700 font-medium text-sm">
              Attachments
            </div>
            {values?.attachments?.map((item: any, index: any) => {
              return (
                <div className="col-span-3 h-[200px]" key={index}>
                  <ATMRenderFile
                    fileInfo={item}
                    isShowCancel={true}
                    onCancelClick={() => {
                      const attachmentInfo = [...values?.attachments];
                      attachmentInfo?.splice(index, 1);
                      setFieldValue("attachments", attachmentInfo);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="flex justify-end mt-3">
          <button
            className="text-primary-main flex gap-1 font-bold items-center"
            type="button"
            onClick={initiateUpload}
            disabled={uploadedFile ? true : false}
          >
            {uploadedFile ? (
              <ClipLoader
                color={"teal"}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <HiOutlinePlusSmall size={20} />
            )}
            Add Attachment
          </button>
        </div>
      </div>

      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default TicketForm;
