import React, { useRef } from "react";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { useReactToPrint } from "react-to-print";
import ATMPageHeader from "src/components/UI/atoms/ATMPageHeader/ATMPageHeader";
import MeetingMOM from "./MeetingMOM";

type Props = {
  rows: any[];
  meetingData: any;
  handleApproveMomClick: () => void;
};

const MeetingMOMTemplate = ({
  rows,
  meetingData,
  handleApproveMomClick,
}: Props) => {
  const meetingMOMRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => meetingMOMRef?.current,
    documentTitle: `${meetingData?.title}(Codiotic-connect)`,
  });
  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <ATMPageHeader
          hideSearchBox={true}
          hideAddButton={true}
          pageTitle="MOM (Minutes Of Meeting)"
        />
        <ATMLoadingButton className=" w-[10%]" onClick={handlePrint}>
          Download
        </ATMLoadingButton>
      </div>

      <MeetingMOM ref={meetingMOMRef} rows={rows} meetingData={meetingData} />

      {meetingData?.status === "COMPLETED" ? (
        <div className="flex justify-end py-5">
          <ATMLoadingButton
            className="w-[10%]"
            onClick={() => handleApproveMomClick()}
          >
            Approve
          </ATMLoadingButton>
        </div>
      ) : null}
    </div>
  );
};

export default MeetingMOMTemplate;
