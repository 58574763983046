import { useDispatch } from "react-redux";
import { setAccessToken } from "src/redux/slices/AuthSlice";
import {
    authTokenKeyName,
    refreshTokenKeyName,
} from "src/utils/configs/authConfig";

export const useAfterLogin = () => {
  const dispatch = useDispatch();
  const handleAfterLogin = (response: any) => {
    localStorage.setItem(authTokenKeyName, response?.token);
    localStorage.setItem(refreshTokenKeyName, response?.refreshToken);
    localStorage.setItem("deviceId", response?.deviceId);
    localStorage.setItem("canApproveETA", response?.canApproveETA);
    localStorage.setItem("canCloseTicket", response?.canCloseTicket);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        name: response?.name,
        mobile: response?.mobile,
        email: response?.email,
        userId: response?.userId,
        clientId: response?.clientId,
      })
    );
    dispatch(setAccessToken(response?.accessToken));
  };
  return { handleAfterLogin };
};
