export enum ENMMeetingStatus {
  "PENDING" = "Scheduled Meeting",
  "COMPLETED" = "Approval Pending",
  "FREEZE" = "Approval Done",
}

export type TYPMeetingStatus = keyof typeof ENMMeetingStatus;

type Props = {
  status: TYPMeetingStatus;
};

const getClassNameByStatus = (status: TYPMeetingStatus) => {
  switch (status) {
    case "COMPLETED":
      return "text-green-500 bg-green-100 border-green-500";

    case "PENDING":
      return "text-yellow-500 bg-yellow-100 border-yellow-500";
    case "FREEZE":
      return "text-slate-500 bg-slate-100 border-slate-500";
  }
};

const MOLMeetingStatusChip = ({ status }: Props) => {
  const className = ` 
      font-medium
      rounded-full
      px-2
      py-[2px]
      border
      text-[12px]
      border-[1px]
      text-center
      ${getClassNameByStatus(status)}`;

  return <div className={className}> {ENMMeetingStatus[status]} </div>;
};

export default MOLMeetingStatusChip;
