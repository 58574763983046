import apiSlice from "./ApiSlice";

export const ActivityServiceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllActivity: builder.mutation({
      query: (body) => {
        return {
          url: "/ticket-activity/app",
          method: "POST",
          body,
        };
      },
    }),
    getAllActivityDates: builder.query({
      providesTags: ["ticket"],
      query: (body) => {
        return {
          url: "/ticket-activity/app/dates",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useGetAllActivityMutation, useGetAllActivityDatesQuery } =
  ActivityServiceApi;
