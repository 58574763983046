import moment from "moment";
import React from "react";
import ATMMarkdownContainer from "src/components/UI/atoms/ATMMarkdownContainer/ATMMarkdownContainer";

type Props = {
  rows: any[];
  meetingData: any;
};

const MeetingMOM = React.forwardRef(
  ({ rows, meetingData }: Props, ref: any) => {
    return (
      <div className="p-4" ref={ref}>
        <div className="p-4">
          <div className="h-auto mt-8">
            {/* MOM Project name & meeting held date*/}
            <div className="pl-8">
              <div className="flex justify-between">
                <div className="text-lg font-semibold text-[20px] capitalize">
                  {meetingData?.projectName}
                </div>
              </div>

              {/* Meeting Agenda */}
              <div className="flex gap-x-4 mt-4">
                <div>
                  <span className="text-sm font-semibold">Agenda</span>
                </div>
                {" - "}
                <div className="capitalize">{meetingData?.agenda}</div>
              </div>

              {/* Client User & Codiotic User */}
              <div className="flex gap-x-4 mt-2">
                <div>
                  <span className="text-sm font-semibold">Attendees</span>
                </div>
                {" - "}
                <div className="capitalize">
                  {meetingData?.actualClientUsers?.map(
                    (ele: string) => ele + " , "
                  )}
                </div>
              </div>
              <div className="flex gap-x-4 mt-2">
                <div>
                  <span className="text-sm font-semibold">Company</span>
                </div>
                {" - "}
                <div className="capitalize">
                  {meetingData?.actualCodioticUsers?.map(
                    (ele: any) => ele?.name + " , "
                  )}
                </div>
              </div>

              {/* Meeting Location */}
              <div className="flex gap-x-4 mt-2 mb-2">
                <div>
                  <span className="text-sm font-semibold">Location</span>
                </div>
                {" - "}
                <div className="capitalize">
                  {meetingData?.mode === "ONLINE"
                    ? "ONLINE"
                    : meetingData?.address}
                </div>
              </div>
              {/* Time */}
              <div className="flex gap-x-4 mb-6">
                <div>
                  <span className="text-sm font-semibold">Date Time</span>
                </div>
                {" - "}
                <div>
                  <span className="text-sm">
                    {moment(meetingData?.actualStartTime).format("DD MMM YYYY")}
                    {", "}
                    {moment(meetingData?.actualStartTime).format("h:mm A")}{" "}
                    {" to "}
                    {moment(meetingData?.actualEndTime).format("h:mm A")}
                  </span>
                </div>
              </div>
            </div>

            {/* <Divider /> */}

            {rows?.length
              ? rows?.map((ele: any, ind: number) => {
                  return (
                    <div className="flex-1 flex gap-x-2 mt-2">
                      <span className="text-[17px] font-bold mt-1">
                        {ind + 1}
                        {"."}
                      </span>
                      <div className="px-4  py-1">
                        <ATMMarkdownContainer markdown={ele?.notes || "-"} />{" "}
                        {ele?.clientUserId && (
                          <span className="text-xs font-bold opacity-90 flex gap-x-2">
                            ({ele?.clientUserId})
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
);

export default MeetingMOM;
