import { FormikProps } from "formik";
import ATMRenderFile from "src/components/UI/atoms/ATMRenderFile/ATMRenderFile";
import ATMTextArea from "src/components/UI/atoms/formFields/ATMTextArea/ATMTextArea";
import { AddResourceRequestFormValues } from "src/models/CloseResourceRequest.model";
import { getDataWithRightFormat } from "src/screens/Tickets/View/TicketTabs/TicketDetails/TicketDetails";

type Props = {
  formikProps: FormikProps<AddResourceRequestFormValues>;
  onClose: () => void;
  resourceData: any;
};

const ViewResourceRequestForm = ({
  formikProps,
  onClose,
  resourceData,
}: Props) => {
  const { values, setFieldValue } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">View Resource </div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex p-4 bg-slate-50 rounded gap-16">
          <div className="flex flex-col">
            <span className="text-xs">Project</span>
            <span className="font-medium text-slate-800 uppercase">
              {resourceData?.projectName}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-xs">Resource Name</span>
            <span className="font-medium text-slate-800">
              {resourceData?.resourceName}
            </span>
          </div>
        </div>
        {resourceData?.description ? (
          <div className="flex flex-col w-full bg-slate-50 rounded p-4">
            <span className="text-xs">Description</span>
            <span className="font-medium text-slate-800">
              {" "}
              {getDataWithRightFormat(resourceData?.description)}
            </span>
          </div>
        ) : null}

        {/* Remark */}
        <div className="">
          <ATMTextArea 
          disabled
            name="remark"
            value={values.remark}
            onChange={(value) => setFieldValue("remark", value)}
            label="Remark"
            minRows={8}
            placeholder="Enter Remark"
          />
        </div>

        {values?.attachments?.length ? (
          <div className="grid grid-cols-12 gap-4 pb-10">
            <div className="col-span-12 text-slate-700 font-medium text-sm">
              Attachments
            </div>
            {values?.attachments?.map((item: any, index: any) => {
              return (
                <div className="col-span-3 h-[200px]" key={index}>
                  <ATMRenderFile
                    fileInfo={item}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>

    </div>
  );
};

export default ViewResourceRequestForm;
