import React, { ReactNode, useEffect } from "react";
import { IconType } from "react-icons";
import ATMHorizontalScroll from "../ATMHorizontalScroll/ATMHorizontalScroll";

export type Tabs = {
  icon: IconType;
  label: string;
  path?: string;
  onClick: (tab: any) => void;
};

type Props = {
  tabs: Tabs[];
  selected: (tab: Tabs) => boolean;
  children?: ReactNode;
  childrenLabel: string;
};

const ATMTab = ({ tabs, selected, childrenLabel = "", children }: Props) => {
  useEffect(() => {
    document.getElementById("scroll-to-view")?.scrollIntoView();
  }, []);

  return (
    <ATMHorizontalScroll>
      {(params) => (
        <div
          {...params}
          className="flex text-neutral border-b border-divider w-full overflow-auto scrollbar-hide scroll-smooth px-4 "
        >
          {tabs?.map((tab, tabIndex) => {
            return (
              <button
                id={selected(tab) ? "scroll-to-view" : ""}
                key={tabIndex}
                type="button"
                className={`relative flex gap-2 py-2 px-4 border-y-2 border-white text-body-1 min-w-fit font-medium hover:text-primary-dark  items-center h-full transition-all duration-500  ${
                  selected(tab)
                    ? " text-primary-dark  border-b-primary-dark "
                    : " "
                }`}
                onClick={() => {
                  !selected(tab) && tab.onClick(tab);
                }}
              >
                <div className="text-tm-base">{tab.icon({})}</div>
                <div className="">{tab.label}</div>

                {tab.label === childrenLabel ? children : null}
              </button>
            );
          })}
        </div>
      )}
    </ATMHorizontalScroll>
  );
};

export default ATMTab;
