import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import ApproveETARequest from "./ApproveETARequest";
import { showToast } from "src/utils/showToaster";
import { useParams } from "react-router-dom";
import { useChangeRequestApproveEtaRequestMutation } from "src/services/ChangeRequestService";
type ApproveEtaValue = {
  approvalRemark: string;
};

type Props = {
  onClose: () => void;
};

const ApproveETARequestWrapper = ({ onClose }: Props) => {
  const [approveETA] = useChangeRequestApproveEtaRequestMutation();
  const { changeRequestId } = useParams();

  const initialValues: ApproveEtaValue = {
    approvalRemark: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    approvalRemark: string().required("Please enter remark"),
  });

  // Handle Submit
  const handleSubmit = (
    values: ApproveEtaValue,
    { setSubmitting, resetForm }: FormikHelpers<ApproveEtaValue>
  ) => {
    const formatedValues = {
      approvalStatus: "DISAPPROVED",
      approvalRemark: values?.approvalRemark,
    };
    approveETA({
      body: formatedValues,
      requestId: changeRequestId,
    }).then((res: any) => {
      if (res?.error) {
        setSubmitting(false);
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          resetForm();
          showToast("success", res?.data?.message);
        } else {
          showToast("error", res?.data?.message);
          setSubmitting(false);
        }
      }
    });
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <ApproveETARequest formikProps={formikProps} onClose={onClose} />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ApproveETARequestWrapper;
