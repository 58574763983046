import React from "react";
import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { ChangePasswordFormValues } from "src/models/ChangePassword.model";
import ATMPassword from "src/components/UI/atoms/formFields/ATMPassword/ATMPassword";

type Props = {
  formikProps: FormikProps<ChangePasswordFormValues>;
  onClose: () => void;
  formType?: "ADD" | "EDIT";
};

const ChangePasswordForm = ({ formikProps, onClose }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;

  return (
    <div className="p-4 flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">Update Password</div>
        <button
          type="button"
          className="hover:bg-hover p-2 rounded"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* Old Password */}
        <div>
          <ATMPassword
            name="currentPassword"
            value={values.currentPassword}
            onChange={(e) => setFieldValue("currentPassword", e.target.value)}
            label="Current password"
            placeholder="Enter current password"
            onBlur={handleBlur}
          />
        </div>

        {/* New Password */}
        <div>
          <ATMPassword
            name="newPassword"
            value={values.newPassword}
            onChange={(e) => setFieldValue("newPassword", e.target.value)}
            label="New password"
            placeholder="Enter new password"
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Save
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
