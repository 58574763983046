export type AddTicketFormValues = {
  projectId: any;
  title: string;
  priority: any;
  description: any;
  attachments?: any;
};

export type TicketFormValues = {
  problemStatement: string;
  proposedSolution: string;
  flow: string;
  technicalApproach: string;
  affectedAreas: {
    projectId: {
      projectName?: string;
      _id?: string;
    };
    featureId: {
      featureName?: string;
      _id?: string;
    };
  }[];
};

enum TicketPriorityEnum {
  low = "LOW",
  medium = "MEDIUM",
  high = "HIGH",
  urgent = "URGENT",
}

enum TicketType {
  bug = "BUG",
  support = "SUPPORT",
  changeReuest = "CHANGE_REQUEST",
  empty = "",
}

enum TicketStatusEnum {
  pending = "  PENDING",
  mapped = "MAPPED",
  approvalPending = "APPROVAL_PENDING",
  approvalDone = "APPROVAL_DONE",
  done = "DONE",
  open = "OPEN",
  assigned = "ASSIGNED",
  closed = "CLOSED",
}

//
export type TicketListResponse = {
  _id: string;
  ticketNumber: string;
  projectId: string;
  moduleId: string;
  featureId: string;
  problemStatement: string;
  projectLabel: string;
  createdByLabel: string;
  unReadMessages: number;
  problemSolution: string;
  flow: string;
  technicalApproach: string;
  numberOfDeveloper: number;
  timeTaken: string;
  responsibleMembers: string[];
  issueFrom: string;
  remark: string;
  changeLog: string;
  reasonOfBug: string;
  estCost: null;
  numberOfMembers: null;
  isEtaApproved: boolean;
  attachments: string[];
  title: string;
  description: string;
  estTime: string;
  priority: TicketPriorityEnum;
  status: TicketStatusEnum;
  type: TicketType;
  assignedTo: string | null;
  createdBy: string;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

//
export type SingleTicketResponseType = {
  _id: string;
  ticketNumber: string;
  projectId: string;
  moduleId: string | null;
  featureId: string | null;
  problemStatement: string;
  type: string;
  problemSolution: string;
  flow: string;
  technicalApproach: string;
  affectedAreas: {
    projectId: string;
    featureId: string;
    _id: string;
    projectLabel: string;
    featureLabel: string;
  }[];
  numberOfDeveloper: number | null;
  timeTaken: number | null;
  responsibleMembers: string[];
  remark: string;
  changeLog: string;
  reasonOfBug: string;
  estTime: string | null;
  estCost: number | null;
  numberOfMembers: number | null;
  isEtaApproved: boolean;
  title: string;
  description: string;
  priority: string;
  status: string;
  assignedTo: string | null;
  createdBy: string;
  isDeleted: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  changeRequestName: string;
  __v: number;
  projectLabel: string;
  createdByLabel: string;
  attachments: {
    type: string;
    url: string;
    _id: string;
  }[];
};

export enum FILE_FORMAT {
  pdfs = "DOCUMENT",
  videos = "VIDEO",
  images = "IMAGE",
  xlsx = "DOCUMENT",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  PDF = "PDF",
  XLSX = "XLSX",
}
