export enum ENMTicketStatus {
  "UNMAPPED" = "Pending",
  "MAPPED" = "Mapped",
  "OPEN" = "Open",
  "ASSIGNED" = "Assigned",
  "CLOSED" = "Closed",
  "APPROVAL_PENDING" = "Approval Pending",
  "DONE" = "Done",
  "APPROVAL_DONE" = "Approval Done",
  "CLOSE" = "Close",
  "IN_PROGRESS" = "In Progress",
}

export type TYPTicketStatus = keyof typeof ENMTicketStatus;

type Props = {
  status: TYPTicketStatus;
};

const getClassNameByStatus = (status: TYPTicketStatus) => {
  switch (status) {
    case "UNMAPPED":
      return "text-[#000000] border-[#CCC] bg-[#FFFF001A]";

    case "MAPPED":
      return "text-[#0000FF] border-[#00F] bg-[#0000FF33]";

    case "APPROVAL_PENDING":
      return "text-[#FFA500] border-[#FFA500] bg-[#FFA50033]";

    case "APPROVAL_DONE":
      return "text-[#008000] border-[#008000] bg-[#00800033]";

    case "OPEN":
      return "text-slate-500 bg-slate-100 border-slate-500";

    case "ASSIGNED":
      return "text-pink-500 bg-pink-100 border-pink-500";

    case "CLOSED":
      return "text-slate-500 bg-slate-100 border-slate-500";

    case "DONE":
      return "text-[#008080] border-[#008080] bg-[#00808033]";

    case "CLOSE":
      return "text-red-500 bg-red-100 border-red-500";

    case "IN_PROGRESS":
      return "text-yellow-500 bg-yellow-100 border-yellow-500";
  }
};

const MOLTicketStatusChip = ({ status }: Props) => {
  const className = ` 
    font-medium
    rounded-full
    px-2
    py-[2px]
    border
    text-[13px]
    border-[1px]
    ${getClassNameByStatus(status)}`;

  return <div className={className}> {ENMTicketStatus[status]} </div>;
};

export default MOLTicketStatusChip;
