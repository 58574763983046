import React from "react";
import { Divider } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import { BiCheck, BiChevronDown } from "react-icons/bi";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import { getDataWithRightFormat } from "src/screens/Tickets/View/TicketTabs/TicketDetails/TicketDetails";
import { useChangeRequestApproveEtaRequestMutation } from "src/services/ChangeRequestService";
import { useApproveTicketETAMutation } from "src/services/TicketService";
import {
  getEstimationInDays,
  getTimeInHours,
} from "src/utils/constants/ticketUtils";
import { showConfirmationDialog } from "src/utils/showConfirmationDialog";
import { showToast } from "src/utils/showToaster";
import ApproveETARequestWrapper from "../Dialogs/ApproveETARequest/ApproveETARequestWrapper";
type Props = {
  getAllTicketData: any;
  changeRequestDetails: any;
};

const TicketSummaryInfo = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  const getValue = useMemo(() => getDataWithRightFormat(value), [value]);

  return (
    <div className="flex flex-col gap-1 ">
      <div className="text-neutral text-base">{label}</div>
      {value ? (
        <div className="bg-surface-light p-3 rounded-md">{getValue}</div>
      ) : (
        "-"
      )}
    </div>
  );
};

const TicketsView = React.forwardRef(
  ({ getAllTicketData, changeRequestDetails }: Props) => {
    const navigate = useNavigate();
    const { changeRequestId } = useParams();
    const [showDeclineDialog, setShowDeclineDialog] = useState(false);
    const [approveETA, { isLoading: isApproving }] =
      useChangeRequestApproveEtaRequestMutation();

    const Accordian = ({ ticketData }: { ticketData: any }) => {
      const [approveETA] = useApproveTicketETAMutation();
      const [isOpen, setIsOpen] = useState(false);
      const getDescription = useMemo(
        () => getDataWithRightFormat(ticketData?.description),
        [ticketData?.description]
      );

      return (
        <div className="border rounded-md shadow-sm ">
          <div
            onClick={() => setIsOpen((prev) => !prev)}
            className="flex justify-between items-center py-1 cursor-pointer"
          >
            <div className="flex gap-4 items-start capitalize py-2">
              <div
                className={`p-2 rounded-full  transition-all duration-300   ${isOpen && "rotate-180"
                  } `}
              >
                <BiChevronDown size={24} />
              </div>
              <div className="">
                {ticketData?.title}

                <div className=" text-[13px] pt- font-medium text-slate-500">
                  <span className="">Estimated Time - </span>
                  <span className="">
                    {" "}
                    {getTimeInHours(ticketData?.estTime || 0)}
                  </span>
                </div>
                <div className=" text-[13px] font-medium text-slate-500">
                  <span className="">No. of Developers - </span>
                  <span className=""> {ticketData?.numberOfMembers || 0}</span>
                </div>
              </div>
            </div>

            {ticketData?.status === "APPROVAL_DONE" ? (
              <div className="px-4 flex items-center gap-2">
                <div className="bg-green-100 text-green-600 rounded-full p-0.5 font-semibold border border-green-600 text-base">
                  <BiCheck size={16} />
                </div>
                <span className="text-base"> Approved </span>
              </div>
            ) : null}
          </div>

          {isOpen ? (
            <>
              <Divider />

              <div className="p-4 flex flex-col gap-4">
                {getDescription}

                <div className="flex flex-col gap-4 divide-dashed divide-y">
                  <TicketSummaryInfo
                    label="Problem Statement"
                    value={ticketData?.problemStatement}
                  />
                  <TicketSummaryInfo
                    label="Proposed Solution"
                    value={ticketData?.problemSolution}
                  />
                  <TicketSummaryInfo label="Flow" value={ticketData?.flow} />
                  <TicketSummaryInfo
                    label="Technical Approach"
                    value={ticketData?.technicalApproach}
                  />

                  <div className="flex flex-col gap-1 pt-2 ">
                    <div className="text-neutral text-base">Affected Areas</div>
                    {ticketData?.affectedAreas?.length ? (
                      <div className="bg-surface-light p-3 rounded-md">
                        {ticketData?.affectedAreas?.map((el: any, ind: any) => {
                          return (
                            <div key={ind}>
                              <span className="text-sm font-semibold">
                                {" "}
                                {`( ${el?.projectLabel || "-"} )`}
                              </span>{" "}
                              {el?.featureLabel || "-"}{" "}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>

                {ticketData?.status === "APPROVAL_PENDING" ? (
                  <div className="flex justify-end">
                    <div>
                      <ATMLoadingButton
                        onClick={() => {
                          showConfirmationDialog({
                            title: "Are you sure?",
                            text: "Want to approve ticket?",
                            showCancelButton: true,
                            next: (result) => {
                              if (result?.isConfirmed) {
                                approveETA({
                                  body: { isEtaApproved: true },
                                  ticketId: ticketData?._id,
                                }).then((res: any) => {
                                  if (res?.error) {
                                    showToast(
                                      "error",
                                      res?.error?.data?.message
                                    );
                                  } else {
                                    if (res?.data?.status) {
                                      showToast("success", res?.data?.message);
                                    } else {
                                      showToast("error", res?.data?.message);
                                    }
                                  }
                                });
                              }
                            },
                          });
                        }}
                      >
                        Approve Ticket
                      </ATMLoadingButton>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      );
    };

    const getuUnapprovedTicketCount = (tickets: any[]) => {
      return tickets?.filter((ticket) => ticket?.status === "APPROVAL_PENDING")
        ?.length;
    };

    //get meetings time, man hour time, and developer
    const getMeetingTime = (changeRequestDetails: any) => {
      // eslint-disable-next-line array-callback-return
      let totalMeetingTime = 0;
      let totalManTime = 0;

      return changeRequestDetails?.meetings?.reduce(
        (acc: any, meetingData: any) => {
          totalMeetingTime += Number(meetingData?.meetingTimeInMins || 0);
          totalManTime += Number(meetingData?.totalMainHrs || 0);

          return {
            ...acc,
            meetingTime: totalMeetingTime,
            manTime: totalManTime,
          };
        },
        {}
      );
    };
    //get analysis time, man hour time, and developer
    const getAnalysisMeetingTime = (changeRequestDetails: any) => {
      // eslint-disable-next-line array-callback-return
      let totalMeetingTime = 0;
      let totalManTime = 0;

      return changeRequestDetails?.analysisMeetings?.reduce(
        (acc: any, analysisMeetingData: any) => {
          totalMeetingTime += Number(
            analysisMeetingData?.meetingTimeInMins || 0
          );
          totalManTime += Number(analysisMeetingData?.totalMainHrs || 0);

          return {
            ...acc,
            meetingTime: totalMeetingTime,
            manTime: totalManTime,
          };
        },
        {}
      );
    };
    const meetingData = useMemo(
      () => getMeetingTime(changeRequestDetails),
      [changeRequestDetails]
    );
    const analysisMeetingTimeData = useMemo(
      () => getAnalysisMeetingTime(changeRequestDetails),
      [changeRequestDetails]
    );

    const estimatedInDays = getEstimationInDays({
      time:
        Number(changeRequestDetails?.devAndQaTime || 0) +
        Number(
          changeRequestDetails?.deploymentTime?.timeSpent || 0
        ) +
        Number(changeRequestDetails?.demoTime?.timeSpent || 0),
      workingHours:
        changeRequestDetails?.projectPerDayWorkingHour || 0,
    })

    const finalEstimateDay = estimatedInDays.toString().trim();
    const convertEstimatedDaytoNumber = parseFloat(finalEstimateDay);


    return (
      <>
        <div className="flex gap-4">
          {/* Tickets */}
          <div className="flex-1 flex flex-col   ">
            <div className="flex justify-between items-center  py-4 shadow-[0_10px_35px_-25px_rgba(0,0,0,0.3)]">
              <div className="flex gap-4 items-center">
                <button
                  type="button"
                  onClick={() => navigate("/change-request")}
                  className="p-2 rounded-full hover:bg-gray-100 transition-all"
                >
                  <IoArrowBack size={24} />
                </button>

                <div className="text-xl font-semibold text-slate-600 capitalize">
                  {" "}
                  {changeRequestDetails?.requestName}
                  <div className="font-semibold text-slate-500 text-sm">
                    {" "}
                    {moment(changeRequestDetails?.createdAt).format(
                      "DD MMM yyyy"
                    )}{" "}
                  </div>
                </div>
              </div>

              <div className="text-red-500 font-semibold bg-red-50 px-2 py-1 rounded">
                Unapproved Tickets (
                <span>{getuUnapprovedTicketCount(getAllTicketData)}</span>)
              </div>
            </div>

            {/* <Divider /> */}

            <div className="flex-1  flex flex-col gap-4 overflow-auto py-4">
              {getAllTicketData?.map((ticketData: any) => {
                return <Accordian ticketData={ticketData} />;
              })}
            </div>
          </div>

          {/* Change request Summary */}
          <div className="w-[30%] h-full py-4">
            <div className="border border-sky-200 rounded-md p-4 flex flex-col gap-4 bg-sky-50 ">
              <div className="text-sky-600 font-medium text-xl"> Overview </div>

              <Divider className="bg-sky-200" />
              {/* Meeting and call  */}
              <div className="">
                <div className="font-semibold text-slate-700">
                  Meetings and calls
                </div>
                <div className="text-neutral  pt-3 text-base">
                  <div className="flex justify-between">
                    <div>No. of meetings </div>
                    <div className=" text-slate-600 font-semibold">
                      {changeRequestDetails?.meetings?.length}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Total Spent Time </div>
                    <div className=" text-slate-600 font-semibold">
                      {getTimeInHours(meetingData?.meetingTime || 0)}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Total man hours spent</div>
                    <div className=" text-slate-600 font-semibold">
                      {getTimeInHours(meetingData?.manTime || 0)}
                    </div>
                  </div>
                </div>
              </div>

              <Divider className="bg-sky-200" />
              {/* Analysis Meeting */}
              <div className="">
                <div className="font-semibold text-slate-700">Analysis</div>
                <div className="text-neutral  pt-3 text-base">
                  {/* <div className="flex justify-between">
                    <div>No. of meetings </div>
                    <div className=" text-slate-600 font-semibold">
                      {changeRequestDetails?.analysisMeetings?.length}
                    </div>
                  </div> */}
                  <div className="flex justify-between">
                    <div>Analysis Time (Man Hours)</div>
                    <div className=" text-slate-600 font-semibold">
                      {getTimeInHours(analysisMeetingTimeData?.manTime || 0)}
                    </div>
                  </div>
                  {/* <div className="flex justify-between">
                    <div>Total Spent Time </div>
                    <div className=" text-slate-600 font-semibold">
                      {analysisMeetingTimeData?.meetingTime || 0} min
                    </div>
                  </div> */}
                </div>
              </div>
              <Divider className="bg-sky-200" />
              {/* Implementation and Deployment */}
              <div className="">
                <div className="font-semibold text-slate-700">
                  Implementation & Deployment
                </div>
                <div className="text-[14px] text-slate-500 font-semibold pt-3">
                  Development & QA
                </div>
                <div className="text-neutral text-base py-2">
                  <div className="flex justify-between">
                    <div>Required Team Size </div>
                    <div className=" text-slate-600 font-semibold">
                      {changeRequestDetails?.numberOfDeveloper}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Time Required</div>
                    <div className=" text-slate-600 font-semibold">
                      {getTimeInHours(changeRequestDetails?.devAndQaTime || 0)}
                    </div>
                  </div>
                </div>
                <Divider className="bg-sky-100" />
                <div className="text-[14px] text-slate-500 font-semibold pt-3">
                  Deployment
                </div>
                <div className="text-neutral text-base pt-2">
                  <div className="flex justify-between">
                    <div>Required Team Size </div>
                    <div className=" text-slate-600 font-semibold">
                      {changeRequestDetails?.deploymentTime?.requiredMembers ||
                        0}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Time Required</div>
                    <div className=" text-slate-600 font-semibold">
                      {getTimeInHours(
                        changeRequestDetails?.deploymentTime?.timeSpent || 0
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="bg-sky-200" />
              {/* Demo and Training */}
              <div className="">
                <div className="font-semibold text-slate-700">
                  Demo & Training
                </div>
                <div className="text-neutral text-base pt-3">
                  <div className="flex justify-between">
                    <div>Team Size </div>
                    <div className=" text-slate-600 font-semibold">
                      {changeRequestDetails?.demoTime?.requiredMembers || 0}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Duration of meeting</div>
                    <div className=" text-slate-600 font-semibold">
                      {getTimeInHours(
                        changeRequestDetails?.demoTime?.timeSpent || 0
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>Total man hours</div>
                    <div className=" text-slate-600 font-semibold">
                      {getTimeInHours(
                        Number(changeRequestDetails?.demoTime?.timeSpent || 0) *
                        Number(
                          changeRequestDetails?.demoTime?.requiredMembers || 0
                        )
                      )}{" "}
                    </div>
                  </div>
                </div>
              </div>

              <Divider className="bg-sky-200" />
              <div>
                <div className="font-semibold text-slate-700">Total</div>
              </div>

              <div className="flex justify-between">
                <div className="text-neutral text-base"> Estimated Time </div>

                <div className="text-sm text-slate-600 font-semibold">
                  {estimatedInDays}
                </div>
              </div>

              <div className="flex justify-between">
                <div className="text-neutral text-base">
                  {" "}
                  Estimated Cost{" "}
                  <span className="text-xs">(Excluding Taxes)</span>{" "}
                </div>

                <div className="text-sm text-slate-600 font-semibold flex items-center">
                  <div>&#8377; {changeRequestDetails?.costEst} </div>
                </div>
              </div>
              {changeRequestDetails?.estDeliveryDate && (
                <div className="flex justify-between">
                  <div className="text-neutral text-base">
                    {" "}
                    Estimated Delivery Date <br />
                  </div>

                  <div className="text-sm text-slate-600 font-semibold">
                    {changeRequestDetails?.estDeliveryDate
                      ? moment(changeRequestDetails?.estDeliveryDate).format(
                        "DD MMM YYYY"
                      )
                      : "-"}
                  </div>
                </div>
              )}

              {changeRequestDetails?.approvalStatus === "RAISED" &&
                getuUnapprovedTicketCount(getAllTicketData) === 0 ? (
                <div className="flex flex-col gap-1">
                  <div>
                    <ATMLoadingButton
                      isLoading={isApproving}
                      onClick={() => {
                        showConfirmationDialog({
                          title: "Are you sure ?",
                          text: "You have checked estimation and want to approve it ?",
                          showCancelButton: true,
                          next: ({ isConfirmed }) => {
                            if (isConfirmed) {
                              approveETA({
                                body: {
                                  approvalStatus: "APPROVED",
                                  estimatedTime: convertEstimatedDaytoNumber || 0,
                                },
                                requestId: changeRequestId,
                              }).then((res: any) => {
                                if (res?.error) {
                                  showToast("error", res?.error?.data?.message);
                                } else {
                                  if (res?.data?.status) {
                                    showToast("success", res?.data?.message);
                                  } else {
                                    showToast("error", res?.data?.message);
                                  }
                                }
                              });
                            }
                          },
                        });
                      }}
                    >
                      {" "}
                      Approve Change Request
                    </ATMLoadingButton>
                  </div>

                  {/* <div>
                    <ATMLoadingButton
                      onClick={() => {
                        setShowDeclineDialog(true);
                      }}
                      className="text-neutral bg-transparent hover:bg-white border-transparent hover:border  hover:border-red-500 font-semibold hover:text-red-500"
                    >
                      {" "}
                      Decline
                    </ATMLoadingButton>
                  </div> */}
                </div>
              ) : null}
            </div>

            {changeRequestDetails?.isEtaApproved && (
              <div className="p-4 border border-green-600 rounded-md mt-2 flex gap-4 items-center bg-green-50 ">
                <div className="text-white bg-green-600 rounded-full p-0.5 font-semibold text-base flex justify-center items-center ">
                  <BiCheck size={24} />
                </div>

                <div className="text-base flex-1">
                  {" "}
                  Approved by{" "}
                  <span className="capitalize italic font-semibold">
                    {" "}
                    {changeRequestDetails?.approvedByName}{" "}
                  </span>{" "}
                  on{" "}
                  <span className="font-semibold italic ">
                    {" "}
                    {changeRequestDetails?.approvedOnDate}{" "}
                  </span>{" "}
                </div>
              </div>
            )}
          </div>
        </div>

        {showDeclineDialog && (
          <ApproveETARequestWrapper
            onClose={() => {
              setShowDeclineDialog(false);
            }}
          />
        )}
      </>
    );
  }
);

export default TicketsView;
