import { Form, Formik, FormikHelpers } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAfterLogin } from "src/hooks/useAfterLogin";
import { RootState } from "src/redux/store";
import { useLoginMutation } from "src/services/AuthServices";
import { showToast } from "src/utils/showToaster";
import { object, string } from "yup";
import LoginForm from "./LoginForm";

export type LoginFormInitialValues = {
  email: string;
  password: string;
};

const LoginFormWrapper = () => {
  const navigate = useNavigate();
  const {handleAfterLogin} = useAfterLogin()
  const { accessToken } = useSelector((state: RootState) => state.auth);


  const [login] = useLoginMutation();
  const initialValues: LoginFormInitialValues = {
    email: "",
    password: "",
  };

  const validationSchema = object({
    email: string()
      .email("Please enter valid email")
      .required("Please enter user name"),
    password: string().required("Password is required"),
  });

  const handleSubmit = (
    values: LoginFormInitialValues,
    { setSubmitting }: FormikHelpers<LoginFormInitialValues>
  ) => {
    setSubmitting(true);
    login(values).then((res: any) => {
      setSubmitting(false);
      if (res.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          handleAfterLogin(res?.data?.data);
          window.location.replace("/project");
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };

  useEffect(() => {
    if (accessToken) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <LoginForm formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginFormWrapper;
