import { Slice, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  TicketListResponse,
  SingleTicketResponseType,
} from "src/models/Ticket.model";

export type TicketSliceStateType = {
  items: TicketListResponse[] | [];
  singleItem: any[];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  priority: string[];
  type: string[];
  status: string[];
  createdBy: any;
  projects: any;
  filterBy: {
    fieldName: string;
    value: any;
  }[];
  dateFilter: {
    endDate: string | null;
    startDate: string | null;
  };
  estimatedTimeFilter: {
    start_time: string | null;
    end_time: string | null;
  };
};

const initialState: TicketSliceStateType = {
  items: [],
  singleItem: [],
  totalItems: 0,
  isTableLoading: true,
  page: 1,
  rowsPerPage: 20,
  searchValue: "",
  sortValue: { field: "createdAt", value: "DESC" },
  priority: [],
  type: [],
  status: [],
  createdBy: [],
  projects: [],
  filterBy: [
    {
      fieldName: "priority",
      value: [],
    },
    {
      fieldName: "type",
      value: [],
    },
    {
      fieldName: "clientSideTicketStatus",
      value: [],
    },
    {
      fieldName: "createdBy",
      value: [],
    },
    {
      fieldName: "isArchived",
      value: false,
    },
  ],
  dateFilter: {
    startDate: null,
    endDate: null,
  },
  estimatedTimeFilter: {
    start_time: null,
    end_time: null,
  },
};

const ticketSlice: Slice<TicketSliceStateType> = createSlice({
  name: "ticketSlice",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<any[] | []>) => {
      state.items = action.payload;
    },
    setSingleItem: (
      state,
      action: PayloadAction<SingleTicketResponseType[] | []>
    ) => {
      state.singleItem = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
      state.page = 1;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] }[]>
    ) => {
      state.filterBy = action.payload;
      state.page = 1;
    },
    setPriorityFilter: (state, action: PayloadAction<string[]>) => {
      state.priority = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string[]>) => {
      state.type = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<string[]>) => {
      state.status = action.payload;
    },
    setCreatedByFilter: (state, action: PayloadAction<string[]>) => {
      state.createdBy = action.payload;
    },
    setProjectsFilter: (state, action: PayloadAction<string[]>) => {
      state.projects = action.payload;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ) => {
      state.dateFilter = action.payload;
    },
    setEstimatedTimeFilter: (
      state,
      action: PayloadAction<{ start_time: string; end_time: string }>
    ) => {
      state.estimatedTimeFilter = action.payload;
    },
  },
});

export const {
  setItems,
  setSingleItem,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setDateFilter,
  setPriorityFilter,
  setTypeFilter,
  setStatusFilter,
  setCreatedByFilter,
  setProjectsFilter,
  setEstimatedTimeFilter,
  setFilterBy,
} = ticketSlice.actions;
export default ticketSlice.reducer;
