import React from "react";

export enum ENMTicketType {
  "UNMAPPED" = "Unassigned",
  "MAPPED" = "Mapped",
  "BUG" = "Bug",
  "SUPPORT" = "Support",
  "CHANGE_REQUEST" = "Change Request",
  "OTHER" = "Other",
  "TASK" = "Task",
  "MISSING_FEATURE" = "Missing",
}

export type TYPTicketType = keyof typeof ENMTicketType;

type Props = {
  ticketType: TYPTicketType;
};

const getClassNameByType = (ticketType: TYPTicketType) => {
  switch (ticketType) {
    case "TASK":
      return "text-[#298973] border-[#298973] bg-[#2989731A]";

    case "CHANGE_REQUEST":
      return "text-[#4772E6] border-[#4772E6] bg-[#4772E61A] ";

    case "SUPPORT":
      return "text-[#47A4E6] border-[#47A4E6] bg-[#47A4E61A] ";

    case "OTHER":
      return "text-[#000000] border-[#CCC] bg-[#FFFF001A] ";

    case "BUG":
      return "text-orange-500 border-orange-500 bg-orange-100 ";

    case "MISSING_FEATURE":
      return "text-orange-400 border-orange-400 bg-orange-50";
  }
};

const MOLTicketTypeChip = ({ ticketType }: Props) => {
  const className = ` 
    font-medium
    rounded-full
    px-3
    py-[2px]
    text-[14px]
    border-[1px]
    ${getClassNameByType(ticketType)}`;

  return <div className={className}>{ENMTicketType[ticketType]}</div>;
};

export default MOLTicketTypeChip;
