import React from "react";

type BackendHtmlRendererType = {
  htmlContent: string;
};

const MOLBackendHtmlRenderer = ({
  htmlContent = "-",
}: BackendHtmlRendererType) => {
  return (
    <div>
      {/* Render the HTML content from the backend */}
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default MOLBackendHtmlRenderer;
