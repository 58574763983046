import apiSlice from "./ApiSlice";

export const projectApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getAllProjects: builder.query({
      providesTags: ["project"],
      query: () => {
        return {
          url: "/project/app",
          method: "GET",
        };
      },
    }),


    // GET
    getProjects: builder.query({
      providesTags: ["project"],
      query: (body) => {
        return {
          url: "/project/app",
          method: "POST",
          body,
        };
      },
    }),

    // GET BY ID
    getProject: builder.query({
      providesTags: ["project"],
      query: (projectId) => {
        return {
          url: `project/app/${projectId}`,
          method: "GET",
        };
      },
    }),
    getUserProject: builder.query({
      providesTags: ["project"],
      query: () => {
        return {
          url: "user/app/projects",
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetProjectsQuery,
  useGetProjectQuery,
  useGetUserProjectQuery,
} = projectApi;
